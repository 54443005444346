import { rem } from 'polished';
import styled from 'vue3-styled-components';
import tokens from '@/styles/tokens';
import { props } from './device-card.shared';

const { colors, borderRadius, spacing } = tokens;

export const StDeviceCard = styled('div', props)`
  &.device-card {
    transition: .2s ease-in-out;
    border-radius: ${ () => borderRadius.lg };
    border-width: ${ () => rem(4) };
    border-color: ${ () => colors.primary['300'] };
    padding: ${ () => spacing['4'] } ${ () => spacing['2'] };
    cursor: pointer;
    max-width: ${ () => rem(220) };

    .device-card__svg {
      display: block;
    }

    .device-card__svg svg path, .device-card__content {
      transition: .2s ease-in-out;
    }

    ${ ({ hoverable }) => hoverable ? `
      &:hover {
        border-color: ${ colors.primary.DEFAULT };
        background-color: ${ colors.primary.DEFAULT };
        .device-card__svg svg path {
          fill: white;
        }
        .device-card__content {
          color: white;
        }
      }
    ` : `` }
  }
`;
