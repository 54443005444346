import { QueryFilters } from '@/types/services';
import { AxiosRequestConfig } from 'axios';
import { Ref, UnwrapNestedRefs } from 'vue';

import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'vue-query';

import {
  createDevice,
  deleteDevice,
  getDevice,
  getDevices,
  getNestedDevices,
  updateDevice,
  cloneDevice, getRootDevices,
} from '@/entities/device/services';

import {
  CreateDeviceData,
  DeleteDeviceData,
  Device,
  GetDeviceData,
  UpdateDeviceData,
} from '@/entities/device/types';
import { queryKeyHash } from '@/utils/query';

export const devicesQueryName = 'devices';

export const useGetDevicesQuery = (
  options?: UseQueryOptions<Device[]>,
  filters?: Ref<QueryFilters<Device>>,
  axiosRequestConfig?: AxiosRequestConfig,
) =>
  useQuery(devicesQueryName, () => getDevices(filters?.value, axiosRequestConfig), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
  });

export const useGetDeviceQuery = (
  data: Ref<GetDeviceData>,
  options: UseQueryOptions<Device> = {},
) => useQuery([devicesQueryName, data], () => getDevice(data.value), options);

export const useGetDeviceRootQuery = (
  options?: UseQueryOptions<Device[]>,
  filters?: Ref<QueryFilters<Device>>,
) => useQuery([devicesQueryName, 'root', filters], () => getRootDevices(filters?.value), options);

export const useGetNestedDevicesQuery = (
  data: Ref<GetDeviceData>,
  options?: UseQueryOptions<Device[]>,
) => useQuery([devicesQueryName], () => getNestedDevices(data.value), options);


export const useCreateDeviceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<CreateDeviceData>) => createDevice(data), {
    onSuccess() {
      queryClient.refetchQueries(devicesQueryName, {active: true});
    },
  });
};

export const useUpdateDeviceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<UpdateDeviceData>) => updateDevice(data), {
    onSuccess({ id }) {
      queryClient.refetchQueries(devicesQueryName, {active: true});
      queryClient.refetchQueries([devicesQueryName, id], { active: true });
    },
  });
};

export const useDeleteDeviceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<DeleteDeviceData>) => deleteDevice(data), {
    onSuccess() {
      queryClient.refetchQueries(devicesQueryName, {active: true});
    },
  });
};

export const useCloneDeviceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<UpdateDeviceData>) => cloneDevice(data), {
    onSuccess() {
      queryClient.refetchQueries(devicesQueryName, {active: true});
    },
  });
};
