import styled from 'vue3-styled-components';
import tokens from '@/styles/tokens';
import { rem } from 'polished';
import { fontSizeStyle } from '@/utils/css-in-js';
import { headingProps, Props } from './heading.shared';

const getTypeStyle = ({ type }: Props) => {
  const types = {
    default: {
      ...fontSizeStyle(tokens.fontSize.xxl),
      fontWeight: 'bold',
    },
    light: {
      ...fontSizeStyle(tokens.fontSize.xl),
      fontWeight: 300,
      color: tokens.colors.gray.DEFAULT,
    },
    italic: {
      fontStyle: 'italic',
      ...fontSizeStyle(tokens.fontSize.lg),
    },
    'small-bold': {
      ...fontSizeStyle(tokens.fontSize.md),
      fontWeight: 700,
    },
    'small-italic': {
      ...fontSizeStyle(tokens.fontSize.sm),
      fontWeight: 600,
      fontStyle: 'italic',
    },
    'small-light': {
      ...fontSizeStyle(tokens.fontSize.sm),
      fontWeight: 300,
      color: tokens.colors.gray.DEFAULT,
    },
    underlined: {
      fontWeight: 700,
      paddingBottom: tokens.spacing['1'],
      ...fontSizeStyle(tokens.fontSize.xl),
    },
  };

  return types[type];
};

export const StHeading = styled('h2', headingProps)`
  ${ getTypeStyle }
  text-transform: uppercase;

  ${ ({ type }) =>
  type === 'underlined' ? `
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: ${ rem(1) };
      background-color: ${ tokens.colors.primary.DEFAULT };
    }
  ` : '' }

  ${ ({ color }) => color ? `color: ${ color }` : ''};

  ${ ({ center }) => center ? 'text-align: center' : '' }
`;
