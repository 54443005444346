// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import { createI18n } from 'vue-i18n';

const loadLocaleMessages = () => {
  const locales = require.context('@/i18n/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

  const messages = {};

  locales.keys().forEach((key: string) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);

    if (matched && matched.length > 1) {
      const locale = matched[1];
      (messages as any)[locale] = locales(key);
    }
  });

  return messages;
};

loadLocaleMessages();

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  globalInjection: true,
  messages: loadLocaleMessages(),
});

export default i18n;
