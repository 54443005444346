import { computed, reactive, ref, Ref, watch, ComputedRef } from 'vue';
import { cloneDeep, get } from 'lodash';
import { UseMutationOptions, UseQueryOptions } from 'vue-query';
import { required } from '@vuelidate/validators';
import { ValidationArgs } from '@vuelidate/core';

type UseFormUpdateOptions = {
  fields: Record<any, any>;
  queryHook: any;
  queryData?: Ref;
  queryOptions?: UseQueryOptions<any>;
  mutationHook: any;
  mutationOptions?: UseMutationOptions<any, any, any, any>;
};

type UseFormUpdateResult<T extends UseFormUpdateOptions> = {
  mutation: ReturnType<T['mutationHook']>;
  query: ReturnType<T['queryHook']>;
};

export function useFormUpdate<T extends UseFormUpdateOptions>({
  mutationHook,
  mutationOptions = {},
  queryHook,
  queryData,
  queryOptions = {},
  fields,
}: T): UseFormUpdateResult<T> {
  const dataPopulated = ref(false);
  const query = queryHook(queryData, {
    staleTime: Infinity,
    cacheTime: Infinity,
    ...queryOptions,
  });
  const mutation = mutationHook({ ...mutationOptions });
  const populateFields = () => {
    if (query.data.value && !dataPopulated.value) {
      dataPopulated.value = true;
      const cloneData = cloneDeep(query.data.value);
      Object.entries(cloneData).forEach(([key, value]) => {
        // eslint-disable-next-line no-param-reassign
        fields[key] = value;
      });
    }
  };

  watch(query.data, () => {
    populateFields();
  });

  populateFields();

  return {
    query,
    mutation,
  };
}

type UseFormDeleteOptions = {
  queryHook: any;
  queryData?: Ref;
  queryOptions?: UseQueryOptions<any>;
  mutationHook: any;
  mutationOptions?: UseMutationOptions<any, any, any, any>;
  rules?: any,
  fields?: any,
  canDeleteValuePath: string,
};

type UseFormDeleteResult<T extends UseFormDeleteOptions> = {
  mutation: ReturnType<T['mutationHook']>;
  query: ReturnType<T['queryHook']>;
  canDelete: Ref<boolean>;
  fields: { name: string };
  rules: ValidationArgs;
};

export function useFormDelete<T extends UseFormDeleteOptions>({
  mutationHook,
  mutationOptions = {},
  queryHook,
  queryData,
  queryOptions = {},
  rules = null,
  fields = null,
  canDeleteValuePath = 'name',
}: T): UseFormDeleteResult<T> {
  const query = queryHook(queryData, {
    staleTime: Infinity,
    cacheTime: Infinity,
    ...queryOptions,
  });
  const mutation = mutationHook({ ...mutationOptions });
  const _fields = reactive(fields || {
    name: '',
  });

  const _rules = rules || {
    name: { required },
  };

  const canDelete = computed(() => get(query.data.value, canDeleteValuePath) === get(_fields, canDeleteValuePath, ''));

  return {
    query,
    mutation,
    canDelete,
    fields: _fields,
    rules: _rules,
  };
}
