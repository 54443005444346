import { PropType } from 'vue';
import { Type } from '@/entities/type/types';

export const props = {
  as: {
    type: String,
    default: 'div',
  },
  hoverable: {
    type: Boolean,
    default: true,
  },
  type: {
    type: Number as PropType<Type['id']>,
    default: null,
  },
}
