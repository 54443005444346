import tokens from '@/styles/tokens';
import styled from 'vue3-styled-components';

export const StSelectOption = styled('div')`
  padding: ${ () => tokens.spacing['2'] } ${ () => tokens.spacing['5'] };
  cursor: pointer;

  &:hover, &.is-highlight {
    background-color: ${ () => tokens.colors.gray['100'] };
  }

  &.is-active {
    font-weight: 700;
    color: ${ () => tokens.colors.primary.DEFAULT };
  }
`;
