import { ExtractPropTypes } from 'vue';

export const props = {
  as: {
    type: String,
    default: 'div',
  },
  inline: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    default: () => ({}),
  },
  dateFormat: {
    type: String,
    default: 'iii, MMM dd yyyy',
  }
};

export type Props = ExtractPropTypes<typeof props>
