import { Device } from '@/entities/device/types';
import { GetInspectionGroupData, InspectionGroup } from '@/entities/inspection-group/types';
import { Ref, UnwrapNestedRefs } from 'vue';
import { QueryFilters } from '@/types/services';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'vue-query';

import {
  createInspection,
  deleteInspection,
  getInspection,
  getInspectionImages,
  getInspectionDeviceTree,
  getInspections,
  updateInspection, startInspectionSlicing, getInspectionsByInspectionGroup,
  updateInspectionGroupWithInspection,
} from '@/entities/inspection/services';

import {
  CreateInspectionData,
  DeleteInspectionData, GetInspectionByInspectionGroupData,
  GetInspectionData, GetInspectionDeviceTreeData,
  Inspection, InspectionDeviceTreeData,
  UpdateInspectionData,
} from '@/entities/inspection/types';
import { queryKeyHash } from '@/utils/query';
import { GetInspectionImageData, InspectionImage } from '@/entities/inspection-image/types';

import { inspectionGroupsQueryName } from '@/entities/inspection-group/hooks';

export const inspectionsQueryName = 'inspections';

export const useGetInspectionsQuery = (
  options?: UseQueryOptions<Inspection[]>,
  filters?: Ref<QueryFilters<Inspection>>,
) =>
  useQuery(inspectionsQueryName, () => getInspections(filters?.value), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
  });

export const useGetInspectionDeviceTree = (
  data: Ref<GetInspectionDeviceTreeData>,
  options?: UseQueryOptions<InspectionDeviceTreeData[]>,
) => useQuery([inspectionsQueryName, 'tree', data], () => getInspectionDeviceTree(data.value), options);

export const useGetInspectionQuery = (data: Ref<GetInspectionData>, options: UseQueryOptions<Inspection>) =>
  useQuery([inspectionsQueryName, data], () => getInspection(data.value), options);

export const useGetInspectionsByInspectionGroupQuery = (data: Ref<GetInspectionByInspectionGroupData>, options: UseQueryOptions<Inspection[] | Inspection>) =>
  useQuery([inspectionsQueryName, data], () => getInspectionsByInspectionGroup(data.value), options);

export const useCreateInspectionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<CreateInspectionData>) => createInspection(data), {
    onSuccess() {
      queryClient.refetchQueries(inspectionsQueryName, {active: true});
      queryClient.refetchQueries(inspectionGroupsQueryName, {active: true});
    },
  });
};

export const useUpdateInspectionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<UpdateInspectionData>) => updateInspection(data), {
    onSuccess({ id }) {
      queryClient.refetchQueries(inspectionsQueryName, {active: true});
      queryClient.refetchQueries([inspectionsQueryName, id], {active: true});
    },
  });
};

export const useUpdateInspectionGroupWithInspectionMutation = () => {
  const queryClient = useQueryClient()
  return useMutation((data: { id: number, inspectionDate: Date, comment: string, inspectedBySupairvision: boolean, weather: number }) => updateInspectionGroupWithInspection(data))
}

export const useDeleteInspectionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<DeleteInspectionData>) => deleteInspection(data), {
    onSuccess() {
      queryClient.refetchQueries(inspectionsQueryName, {active: true});
    },
  });
};

export const useStartInspectionSlicingMutation = () => useMutation((data: GetInspectionData) => startInspectionSlicing(data));

export const useGetInspectionImagesByInspectionQuery = (data: Ref<GetInspectionImageData>, options?: UseQueryOptions<InspectionImage[]>,
) => {
  const queryClient = useQueryClient();
  return useQuery(['inspection-images-by-inspection', data], () => getInspectionImages(data.value), {
    ...options,
  });
};
