import { ExtractPropTypes, PropType } from 'vue';

export const typeValues = [
  'default',
  'light',
  'italic',
  'small-bold',
  'small-italic',
  'small-light',
  'underlined',
] as const;

export const headingProps = {
  as: {
    type: String,
    default: 'h2',
  },
  type: {
    type: String as PropType<typeof typeValues[number]>,
    default: 'default',
  },
  center: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: '',
  }
};

export type Props = ExtractPropTypes<typeof headingProps>;
