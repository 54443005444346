import { ExtractPropTypes, PropType } from 'vue';

export type PushOptions = {
	name: string;	
	params?: Record<string, any>;
};

export type DataType = {
	id: string;
	label: string;
	route: PushOptions;
};

export const props = {
	data: {
		type: Array as PropType<DataType[]>,
		default: () => []
	},
	loading: {
		type: Boolean,
		default: false
	}
};


export type Props = ExtractPropTypes<typeof props>;
