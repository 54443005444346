import { annotationsQueryName } from '@/entities/annotation/hooks';
import { QueryFilters } from '@/types/services';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'vue-query';
import {
  createAnnotationGroup,
  deleteAnnotationGroup,
  getAnnotationGroup,
  getAnnotationGroups,
  updateAnnotationGroup,
} from '@/entities/annotation-group/services';
import {
  AnnotationGroup,
  CreateAnnotationGroupData,
  DeleteAnnotationGroupData,
  GetAnnotationGroupData,
  UpdateAnnotationGroupData,
} from '@/entities/annotation-group/types';
import { Ref, UnwrapNestedRefs } from 'vue';
import { queryKeyHash } from '@/utils/query';

export const annotationGroupsQueryName = 'annotationGroups';

export const useGetAnnotationGroupsQuery = (
  options: UseQueryOptions<AnnotationGroup[]>,
  filters: Ref<QueryFilters<AnnotationGroup>>,
) =>
  useQuery(annotationGroupsQueryName, () => getAnnotationGroups(filters?.value), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
  });

export const useGetAnnotationGroupQuery = (
  data: Ref<GetAnnotationGroupData>,
  options?: UseQueryOptions<AnnotationGroup>,
) => useQuery([annotationGroupsQueryName, data], () => getAnnotationGroup(data.value), options);

export const useCreateAnnotationGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<CreateAnnotationGroupData>) => createAnnotationGroup(data),
    {
      onSuccess() {
        queryClient.refetchQueries(annotationGroupsQueryName, { active: true });
        // annotations.forEach((id) => {
        //   queryClient.refetchQueries([annotationsQueryName, { id }], { active: true });
        // });
      },
    },
  );
};

export const useUpdateAnnotationGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<UpdateAnnotationGroupData>) => updateAnnotationGroup(data),
    {
      onSuccess({ id }) {
        queryClient.refetchQueries(annotationGroupsQueryName, {active: true});
        queryClient.refetchQueries([annotationGroupsQueryName, id], {active: true});
      },
    },
  );
};

export const useDeleteAnnotationGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<DeleteAnnotationGroupData>) => deleteAnnotationGroup(data),
    {
      onSuccess() {
        queryClient.refetchQueries(annotationGroupsQueryName, {active: true});
      },
    },
  );
};
