import styled from 'vue3-styled-components';

import { props } from './dropzone.shared';

export const StDropzone = styled('div', props)`
  form.dropzone {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-style: dashed;
  }
`;
