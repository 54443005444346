import { ExtractPropTypes, PropType } from 'vue';
import { DEFAULT_COLORS as colors } from '@/core/consts';

export const sizeValues = ['sm', 'md'] as const;
export const colorValues = Object.keys(colors) as (keyof typeof colors)[];

export const props = {
  as: {
    type: String,
    default: 'span',
  },
  size: {
    type: String as PropType<typeof sizeValues[number]>,
    default: 'md',
  },
  type: {
    type: String as PropType<typeof colorValues[number]>,
    default: 'primary',
  },
  plain: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: '',
  }
};

export type Props = ExtractPropTypes<typeof props>;
