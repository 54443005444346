import { api } from '@/services';

import {
  VoltaMeasure,
  GetVoltaMeasureData,
  CreateVoltaMeasureData,
  UpdateVoltaMeasureData,
  DeleteVoltaMeasureData,
  UploadUpdateVoltaMeasureData,
} from '@/entities/volta-measure/types';

import { QueryFilters } from '@/types/services';
import { pathWithQuery } from '@/utils/string';

export const getVoltaMeasure = (data: GetVoltaMeasureData): Promise<VoltaMeasure> =>
  api.get(`/volta_measures/${ data.id }`);

export const getVoltaMeasures = (
  filters: QueryFilters<VoltaMeasure> | undefined = undefined,
): Promise<VoltaMeasure[]> => api.get(pathWithQuery('/volta_measures', filters));

export const createVoltaMeasure = (data: CreateVoltaMeasureData): Promise<VoltaMeasure> =>
  api.post('/volta_measures', data);

export const updateVoltaMeasure = (data: UpdateVoltaMeasureData): Promise<VoltaMeasure> =>
  api.put(`/volta_measures/${ data.id }`, data);


export const deleteVoltaMeasure = ({ id }: DeleteVoltaMeasureData): Promise<VoltaMeasure> =>
  api.delete(`/volta_measures/${ id }`);

export const uploadUpdateVoltaMeasure = (payload: UploadUpdateVoltaMeasureData): Promise<VoltaMeasure> =>
  api.post(`/volta_measures/${ payload.id }/upload`, payload.data)