import tokens from '@/styles/tokens';
import { rem } from 'polished';
import styled from 'vue3-styled-components';
import { props } from './breadcrumb-item.shared';

export const StBreadcrumbItem = styled('div', props)`
  display: flex;
  align-items: center;
  gap: ${ () => tokens.spacing['4'] };
  position: relative;

  .nk-breadcrumb-item__toggler {
    cursor: pointer;
  }

  &.is-open {
    .nk-popper {
      .nk-breadcrumb-item__toggler {
        path {
          fill: ${() => tokens.colors.primary['300']};
        }
      }
    }
  }
`;
