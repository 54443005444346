import { api } from '@/services';
import {
  AnnotationGroup,
  CreateAnnotationGroupData,
  DeleteAnnotationGroupData,
  GetAnnotationGroupData,
  UpdateAnnotationGroupData,
} from '@/entities/annotation-group/types';
import { QueryFilters } from '@/types/services';
import { pathWithQuery } from '@/utils/string';

export const getAnnotationGroup = ({ id }: GetAnnotationGroupData): Promise<AnnotationGroup> =>
  api.get(`/annotation_groups/${id}`);

export const getAnnotationGroups = (
  filters: QueryFilters<AnnotationGroup> | undefined = undefined,
): Promise<AnnotationGroup[]> => api.get(pathWithQuery('/annotation_groups', filters));

export const createAnnotationGroup = (data: CreateAnnotationGroupData): Promise<AnnotationGroup> =>
  api.post('/annotation_groups', data);

export const updateAnnotationGroup = (data: UpdateAnnotationGroupData): Promise<AnnotationGroup> =>
  api.put(`/annotation_groups/${data.id}`, data);

export const deleteAnnotationGroup = (data: DeleteAnnotationGroupData): Promise<AnnotationGroup> =>
  api.delete(`/annotation_groups/${data.id}`);
