// export const SHERLOCK = 'sherlock';
// export const VOLTA = 'volta';
// export const CLARITY = 'clarity';
//
// export const getServiceLabel = (serviceId: number) => {
//   switch (serviceId) {
//     case 1 :
//       return VOLTA;
//     case 2 :
//       return CLARITY;
//     case 3 || 4 :
//       return SHERLOCK;
//     default:
//       return null;
//   }
// }

export const VOLTA_ID = 1;
