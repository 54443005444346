import { QueryFilters } from '@/types/services';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'vue-query';
import {
  createAnnotationSeverity,
  deleteAnnotationSeverity,
  getAnnotationSeverities,
  getAnnotationSeverity,
  updateAnnotationSeverity,
} from '@/entities/annotation-severity/services';
import {
  AnnotationSeverity,
  CreateAnnotationSeverityData,
  DeleteAnnotationSeverityData,
  GetAnnotationSeverityData,
  UpdateAnnotationSeverityData,
} from '@/entities/annotation-severity/types';
import { Ref, UnwrapNestedRefs } from 'vue';
import { queryKeyHash } from '@/utils/query';

export const AnnotationSeveritysQueryName = 'AnnotationSeveritys';

export const useGetAnnotationSeveritiesQuery = (
  options: UseQueryOptions<AnnotationSeverity[]> = {},
  filters?: Ref<QueryFilters<AnnotationSeverity>>,
) =>
  useQuery(AnnotationSeveritysQueryName, () => getAnnotationSeverities(filters?.value), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
  });

export const useGetAnnotationSeverityQuery = (
  data: Ref<GetAnnotationSeverityData>,
  options: UseQueryOptions<AnnotationSeverity> = {}
) =>
  useQuery([AnnotationSeveritysQueryName, data], () => getAnnotationSeverity(data.value), {
    ...options,
  });

export const useCreateAnnotationSeverityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<CreateAnnotationSeverityData>) => createAnnotationSeverity(data),
    {
      onSuccess() {
        queryClient.refetchQueries(AnnotationSeveritysQueryName, {active: true});
      },
    },
  );
};

export const useUpdateAnnotationSeverityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<UpdateAnnotationSeverityData>) => updateAnnotationSeverity(data),
    {
      onSuccess({ id }) {
        queryClient.refetchQueries(AnnotationSeveritysQueryName, {active: true});
        queryClient.refetchQueries([AnnotationSeveritysQueryName, id], {active: true});
      },
    },
  );
};

export const useDeleteAnnotationSeverityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<DeleteAnnotationSeverityData>) => deleteAnnotationSeverity(data),
    {
      onSuccess() {
        queryClient.refetchQueries(AnnotationSeveritysQueryName, {active: true});
      },
    },
  );
};
