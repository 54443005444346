import styled from 'vue3-styled-components';
import 'element-plus/es/components/date-picker/style/css';
import tokens from "@/styles/tokens";

const { colors, borderRadius, borderWidth } = tokens;


export const StColorPicker = styled('span')`
  .el-color-picker {
    width: 100%;

    .el-color-picker__trigger {
      width: 100%;
      height: 40px;
      border: ${() => borderWidth.DEFAULT} solid ${() => colors.primary.DEFAULT};
      border-radius: ${() => borderRadius.DEFAULT};
    }
  }
`;
