import type { RouteMiddlewareContext, Middleware } from '@/router/types';

const pipeline = (ctx: RouteMiddlewareContext, middlewares: Middleware[], index: number) => {
  const nextMiddleware = middlewares[index];

  if (!nextMiddleware) {
    return ctx.next;
  }

  return async () => {
    const nextPipeline = pipeline(
      ctx, middlewares, index + 1,
    );

    await nextMiddleware({ ...ctx, next: nextPipeline });
  };
};

export default pipeline;
