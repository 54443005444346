import tokens from '@/styles/tokens';
import { fontSizeStyle } from '@/utils/css-in-js';
import { rem } from 'polished';
import styled from 'vue3-styled-components';
import { props } from './button-link.shared';

export const StButtonLink = styled('div', props)`
  padding: ${ () => tokens.spacing['4'] } ${ () => tokens.spacing['5'] };
  border: ${ () => rem(1.5) } solid ${ () => tokens.colors.black.DEFAULT };
  border-radius: ${ () => tokens.borderRadius.DEFAULT };
  color: ${ () => tokens.colors.black.DEFAULT };
  min-width: ${ () => rem(210) };
  width: fit-content;
  font-weight: 600;
  text-transform: uppercase;
  ${ () => fontSizeStyle(tokens.fontSize.xxs) };
  transition: all .2s ease-in-out;

  &:hover {
    color: ${ () => tokens.colors.primary.DEFAULT };
    border-color: ${() => tokens.colors.primary.DEFAULT};
  }
`;
