import { createApp } from 'vue';
import { VueQueryPlugin } from 'vue-query';

import VueClickAway from 'vue3-click-away';
import ElementPlus from 'element-plus';
import { formatDate } from '@/plugins/date-fns';

import i18n from '@/i18n';

import 'element-plus/dist/index.css';

import VueFullscreen from 'vue-fullscreen';
import VueShortey from 'vue3-shortkey';

import App from './App.vue';
import router from './router';
import store from './store';

import '@/styles';
import '@/services/plugin';

const app = createApp(App)
  .use(store)
  .use(i18n)
  .use(router)
  .use(ElementPlus)
  .use(VueQueryPlugin)
  .use(VueClickAway)
  .use(VueFullscreen)
  .use(VueShortey);

app.provide('$format', formatDate);
app.config.globalProperties.$format = formatDate;

app.mount('#app');
