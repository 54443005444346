import { InspectionGroupDetail } from '@/entities/inspection-group/types';
import { QueryFilters } from '@/types/services';
import { AxiosRequestConfig } from 'axios';
import { useQuery, useMutation, useQueryClient, UseQueryOptions } from 'vue-query';
import {
  createOrganization,
  deleteOrganization,
  getOrganization,
  getOrganizations,
  updateOrganization,
  uploadCorporate,
} from '@/entities/organization/services';
import {
  CreateOrganizationData,
  DeleteOrganizationData,
  GetOrganizationData, Organization,
  UpdateOrganizationData,
  UploadOrganizationCorporateData,
} from '@/entities/organization/types';
import { Ref, UnwrapNestedRefs } from 'vue';

export const organizationsQueryName = 'organizations';
export const useGetOrganizationsQuery = (
  options?: UseQueryOptions<Organization[]>,
  filters?: Ref<QueryFilters<Organization>>,
  axiosConfig?: AxiosRequestConfig,
) =>
  useQuery(organizationsQueryName, () => getOrganizations(filters?.value, axiosConfig), { ...options });

export const useGetOrganizationQuery = (data: Ref<GetOrganizationData>) =>
  useQuery([organizationsQueryName, data], () => getOrganization(data.value));

export const useCreateOrganizationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<CreateOrganizationData>) => createOrganization(data), {
    onSuccess() {
      queryClient.refetchQueries(organizationsQueryName, { active: true });
    },
  });
};

export const useUpdateOrganizationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<UpdateOrganizationData>) => updateOrganization(data), {
    onSuccess({ id }) {
      queryClient.refetchQueries(organizationsQueryName, { active: true });
      queryClient.refetchQueries([organizationsQueryName, id], { active: true });
    },
  });
};

export const useDeleteOrganizationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<DeleteOrganizationData>) => deleteOrganization(data), {
    onSuccess() {
      queryClient.refetchQueries(organizationsQueryName, { active: true });
    },
  });
};

export const useUploadOrganizationCorporate = () => {
  const queryClient = useQueryClient()
  return useMutation((data: UnwrapNestedRefs<UploadOrganizationCorporateData>) => uploadCorporate(data), {
    onSuccess({ id }) {
      queryClient.refetchQueries([organizationsQueryName, { id }], { active: true })
    }
  })
}