import { isRef, Ref, ref, watchEffect } from 'vue';
import i18n from '@/i18n';

const { t } = i18n.global;

export const useFormErrorMessageHandler = (_error: Ref) => {
  const error = ref('');

  const assign = () => {
    error.value = _error.value ? (_error.value?.response?.data?.detail || _error.value?.response?.data?.message || _error.value?.message || t('notification.error')) : '';
  };

  if (isRef(_error)) {
    watchEffect(assign);
  } else {
    assign();
  }

  return { error };
};
