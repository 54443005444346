/* eslint-disable */
import styled from 'vue3-styled-components';
import tokens from '@/styles/tokens';
import { props } from './divider.shared';
import { rem } from 'polished';

const { colors, spacing } = tokens;

export const StDivider = styled('div', props)`
  position: relative;
  ${({ direction }) => [direction === 'vertical' ? 'border-right' : 'border-top']}: ${() =>
    rem(2)} solid ${() => colors.gray['300']};
  ${({ direction }) =>
    direction === 'vertical' &&
    `
    display: inline-flex;
  `}
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 ${() => spacing['2']};
    background-color: white;
  }
`;
