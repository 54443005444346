import { ExtractPropTypes, PropType } from 'vue';
import OpenSeadragon from 'openseadragon';

export const injectionKey = Symbol('');
export const props = {
  as: {
    type: String,
    default: 'div',
  },
  options: {
    type: Object as PropType<OpenSeadragon.Options>,
    default: () => ({}),
  },
  injectionKey: {
    type: Symbol,
    default: injectionKey,
  },
};

export type Props = ExtractPropTypes<typeof props>;

export type OSDExposition = {
  isReady: () => Promise<OSDExposition>;
  getViewer: () => OpenSeadragon.Viewer;
  updateViewer: (dziPath: string) => OpenSeadragon.Viewer;
  osd: typeof OpenSeadragon;
};
export type CompleteLocation = {
  location: OpenSeadragon.Rect;
  pixelLocation: OpenSeadragon.Rect;
  viewportLocation: OpenSeadragon.Rect;
};
export type OverlayEventMap = {
  click: (event: {
    initialEvent: OpenSeadragon.MouseTrackerEvent;
    location: OpenSeadragon.Rect;
  }) => void;
  update: (
    event: {
      event: OpenSeadragon.UpdateOverlayEvent;
      pixelX: number;
      pixelY: number;
      pixelWidth: number;
      pixelHeight: number;
    } & OpenSeadragon.Rect,
  ) => void;
};
