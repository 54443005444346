import { getCurrentUser } from '@/entities/user/services';
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router';

import securityRoutes from '@/teams/security/router';
import visionairRoutes from '@/teams/visionair/router';
import administrationRoutes from '@/teams/administration/router';

import pipeline from '@/router/middleware-pipeline';

import type { RouteMiddlewareContext, Middleware } from '@/router/types';

// const store = useStore();
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  ...visionairRoutes,
  ...administrationRoutes,
  ...securityRoutes,
  {
    path: '/',
    redirect: {
      name: 'visionair-dashboard',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'visionair-dashboard' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export const redirectToLogin = () => router.push({ name: 'security-login' });

router.beforeEach(async (to, from, next) => {
  await getCurrentUser().then((data) => {
    store.dispatch('connection/setUser', data);
  }).finally(() => {
    if (!to.meta.middlewares) {
      return next();
    }

    const { middlewares } = to.meta;
    const ctx: RouteMiddlewareContext = {
      to,
      from,
      next,
      store,
      redirectToLogin,
    };

    return (middlewares as Middleware[])[0]({
      ...ctx,
      next: pipeline(ctx, (middlewares as Middleware[]), 1),
    });
  });
});
export default router;
