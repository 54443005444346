import { ExtractPropTypes, PropType } from 'vue';

const typeValues = ['default', 'plain'] as const;

export const props = {
  error: {
    type: Boolean,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<typeof typeValues[number]>,
    default: 'default',
  },
  name: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  checked: {
    type: Boolean,
    default: false,
  },
};

export type Props = ExtractPropTypes<typeof props>;
