import { api } from '@/services';

import {
  Annotation,
  CreateAnnotationData,
  DeleteAnnotationData, GetAnnotationByLastDeviceInspectionData,
  GetAnnotationData,
  UpdateAnnotationData,
} from '@/entities/annotation/types';

import { QueryFilters } from '@/types/services';
import { pathWithQuery } from '@/utils/string';

export const getAnnotation = ({ id }: GetAnnotationData): Promise<Annotation> =>
  api.get(`/annotations/${ id }`);

export const getAnnotations = (
  filters: QueryFilters<Annotation> | undefined = undefined,
): Promise<Annotation[]> => api.get(pathWithQuery('/annotations', filters));

export const getAnnotationsByLastDeviceInspection = (params: GetAnnotationByLastDeviceInspectionData): Promise<Annotation[]> => api.get('/annotations/by-last-device-inspection', { params });

export const createAnnotation = (data: CreateAnnotationData): Promise<Annotation> =>
  api.post('/annotations', data);

export const updateAnnotation = (data: UpdateAnnotationData): Promise<Annotation> =>
  api.put(`/annotations/${ data.id }`, data);

export const deleteAnnotation = (data: DeleteAnnotationData): Promise<Annotation> =>
  api.delete(`/annotations/${ data.id }`);
