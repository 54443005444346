import styled from 'vue3-styled-components';
import tokens from '@/styles/tokens';
import { rem } from 'polished';
import { omit } from 'lodash';
import { disabledStyle } from '@/utils/css-in-js';
import { props } from './checkbox.shared';

const { colors, spacing, borderRadius } = tokens;

const stProps = {
  ...omit(props, ['disabled']),
  isDisabled: { type: Boolean, defaultValue: false },
};
export const StCheckbox = styled('input', stProps)`
  appearance: none !important;
  flex-shrink: 0;
  border-width: ${() => rem(1)};
  border-style: solid;
  border-color: ${({ checked, error }) =>
    // eslint-disable-next-line no-nested-ternary
    error ? colors.danger.DEFAULT : checked ? colors.primary['300'] : colors.gray.DEFAULT};
  border-radius: ${() => borderRadius.sm};
  background-color: ${({ checked }) => (checked ? colors.primary.DEFAULT : 'white')};
  width: ${() => spacing['4']};
  height: ${() => spacing['4']};
  ${({ isDisabled }) => disabledStyle(isDisabled)}
`;
