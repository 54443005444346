import { Ref } from 'vue';
import { useQuery, UseQueryOptions } from 'vue-query';

import { getNavigationTree } from '@/entities/app/services';
import { GetNavigationTreeData, NavigationTree } from '@/entities/app/types';

export const useGetNavigationTreeQuery = (
  data: Ref<GetNavigationTreeData>,
  options?: UseQueryOptions<NavigationTree>,
) => useQuery(['navigationTree', data], () => getNavigationTree(data.value), options);
