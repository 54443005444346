import { api } from '@/services';
import {
  CreateOrganizationData,
  DeleteOrganizationData,
  GetOrganizationData,
  Organization,
  UpdateOrganizationData,
  UploadOrganizationCorporateData,
} from '@/entities/organization/types';
import { QueryFilters } from '@/types/services';
import { pathWithQuery } from '@/utils/string';
import { AxiosRequestConfig } from 'axios';

export const getOrganization = ({ id }: GetOrganizationData): Promise<Organization> =>
  api.get(`/organizations/${ id }`);

export const getOrganizations = (filters: QueryFilters<Organization>, axiosConfig: AxiosRequestConfig = {}): Promise<Organization[]> => api.get(pathWithQuery('/organizations', filters), { ...axiosConfig });

export const createOrganization = (data: CreateOrganizationData): Promise<Organization> =>
  api.post('/organizations', data);

export const updateOrganization = (data: UpdateOrganizationData): Promise<Organization> =>
  api.put(`/organizations/${ data.id }`, data);

export const deleteOrganization = (data: DeleteOrganizationData): Promise<Organization> =>
  api.delete(`/organizations/${ data.id }`);

  export const uploadCorporate = (payload: UploadOrganizationCorporateData): Promise<Organization> => api.post(`/organizations/${ payload.id }/upload_corporate/${ payload.type }`, payload.data)