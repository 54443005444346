import tokens from '@/styles/tokens';

const { colors } = tokens;

export default `
// -------- STYLE FOR BLADE ----------- //
.device-svg-coloration {
  &[data-svg-name="blade"] {
    &[data-svg-index="0"] svg path[data-blade="0"] {
      fill: ${ colors.primary.DEFAULT };
    }

    &[data-svg-index="1"] svg path[data-blade="1"] {
      fill: ${ colors.primary.DEFAULT };
    }

    &[data-svg-index="2"] svg path[data-blade="2"] {
      fill: ${ colors.primary.DEFAULT };
    }
  }

  // -------- STYLE FOR MAST ----------- //
  &[data-svg-name="tower"] svg path[data-mast] {
    fill: ${ colors.primary.DEFAULT };
  }
}
`;
