import tokens from '@/styles/tokens';
import { rem } from 'polished';
import styled from 'vue3-styled-components';

export const NAVIGATION_HEIGHT = rem(90);
export const SUBNAVIGATION_HEIGHT = rem(90);

export const StNavigation = styled('div')`
   height: ${() => NAVIGATION_HEIGHT};
   background-color: ${ () => tokens.colors.primary['50'] };
   padding: ${ () => tokens.spacing['5'] } ${ () => tokens.spacing['6'] };
   border-bottom: ${ () => rem(3) } solid ${ () => tokens.colors.primary.DEFAULT };
`;
