import styled from 'vue3-styled-components';
import tokens from '@/styles/tokens';
import { rem } from 'polished';
import { DEFAULT_COLORS as colors } from '@/core/consts';
import { fontSizeStyle } from '@/utils/css-in-js';
import { colorValues, props, Props } from './badge.shared';

type ColorStyle = {
  backgroundColor: string;
  color: string;
  borderColor: string;
};

const getTypeStyle = ({ size }: Props) => {
  const styles = {
    md: {
      paddingLeft: `${rem(10)}`,
      paddingRight: `${rem(10)}`,
      height: rem(32),
      borderRadius: tokens.borderRadius.md,
      ...fontSizeStyle(tokens.fontSize.DEFAULT),
      lineHeight: 1,
    },
    sm: {
      paddingLeft: rem(5),
      paddingRight: rem(5),
      minWidth: rem(115),
      height: rem(24),
      borderRadius: tokens.borderRadius.sm,
      ...fontSizeStyle(tokens.fontSize.xxs2),
      lineHeight: 1,
    },
  };

  return styles[size];
};

const getColorStyle = ({ type, plain, color }: Props) => {
  const styles = {
    ...(Object.fromEntries(
      colorValues.map((colorKey) => {
        const getColor = color || colors[colorKey];

        return [
          colorKey,
          {
            backgroundColor: plain ? getColor : 'white',
            color: plain ? 'white' : getColor,
            borderColor: getColor,
          },
        ]
      }),
    ) as Record<typeof colorValues[number], ColorStyle>),
  };

  return styles[type];
};

export const StBadge = styled('span', props)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  letter-spacing: ${() => tokens.letterSpacing.DEFAULT};
  text-transform: uppercase;
  width: fit-content;
  text-align: center;
  border-width: ${() => rem(1)};
  border-style: solid;
  line-height: 1;
  ${getTypeStyle}
  ${getColorStyle}
`;
