<template>
  <span @keydown.enter="openDialog" @click.prevent.stop="openDialog">
    <slot />
  </span>

  <NkModal v-model="dialogVisible" v-bind="$attrs" destroy-on-close>
    <template
      v-for="(_, slot) of modalSlots"
      v-slot:[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope || {}"
      />
    </template>
    <template #default>
      <component v-if="$props.content" :is="$props.content"/>
      <slot v-else name="content" :close="closeDialog"></slot>
    </template>
  </NkModal>

</template>

<script setup>
import { defineProps, ref, useSlots, defineExpose } from 'vue';
import { pickBy } from 'lodash';

import { NkModal } from '@/core/components';

import { props as propsDefinition } from './inline-modal.shared';

defineProps(propsDefinition);

const dialogVisible = ref(false)
const openDialog = () => {
  dialogVisible.value = true;
}
const closeDialog = () => {
  dialogVisible.value = false;
}
const slots = useSlots()
const modalSlots = pickBy(slots, (_, name) => !['default', 'reference'].includes(name))

defineExpose({
  openDialog,
  closeDialog,
})
</script>
