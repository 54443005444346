export const props = {
  label: {
    type: String,
    default: '',
  },
  value: {
    type: [Array, Object, String, Number],
    default: '',
  },
};
