import { ref, Ref, UnwrapNestedRefs } from 'vue';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'vue-query';

import {
  createAnnotation,
  deleteAnnotation,
  getAnnotation,
  getAnnotations, getAnnotationsByLastDeviceInspection,
  updateAnnotation,
} from '@/entities/annotation/services';

import {
  Annotation,
  CreateAnnotationData,
  DeleteAnnotationData, GetAnnotationByLastDeviceInspectionData,
  GetAnnotationData,
  UpdateAnnotationData,
} from '@/entities/annotation/types';

import { QueryFilters } from '@/types/services';
import { inspectionsQueryName } from '@/entities/inspection/hooks';
import { queryKeyHash } from '@/utils/query';

export const annotationsQueryName = 'annotations';

export const useGetAnnotationsQuery = (
  options?: UseQueryOptions<Annotation[]>,
  filters?: Ref<QueryFilters<Annotation>>,
) =>
  useQuery(annotationsQueryName, () => getAnnotations(filters?.value), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
  });

export const useGetAnnotationQuery = (
  data: Ref<GetAnnotationData>,
  options?: UseQueryOptions<Annotation>,
) => useQuery([annotationsQueryName, data], () => getAnnotation(data.value), options);

export const useGetAnnotationByLastDeviceInspectionQuery = (
  data: Ref<GetAnnotationByLastDeviceInspectionData>,
  options?: UseQueryOptions<Annotation[]>,
) => useQuery([annotationsQueryName, data], () => getAnnotationsByLastDeviceInspection(data.value), options);

export const useCreateAnnotationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<CreateAnnotationData>) => createAnnotation(data), {
    onSuccess(data) {
      const inspection = ref(data.inspection);
      queryClient.refetchQueries(inspectionsQueryName, {active: true});
      queryClient.refetchQueries([inspectionsQueryName, inspection], {active: true});
      queryClient.refetchQueries([annotationsQueryName, { id: data.id }], {active: true});
    },
  });
};

export const useUpdateAnnotationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<UpdateAnnotationData>) => updateAnnotation(data), {
    onSuccess({ id }) {
      queryClient.refetchQueries(annotationsQueryName, { active: true });
      queryClient.refetchQueries([annotationsQueryName, id], { active: true });
    },
  });
};

export const useDeleteAnnotationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<DeleteAnnotationData>) => deleteAnnotation(data), {
    onSuccess() {
      queryClient.refetchQueries(annotationsQueryName, { active: true });
    },
  });
};
