import { AxiosRequestConfig } from 'axios';

import { api } from '@/services';
import {
  CreateDeviceGroupData,
	CreateDeviceGroupZipData,
  DeleteDeviceGroupData,
  DeviceGroup,
  GetDeviceGroupData,
  UpdateDeviceGroupData,
	SearchDeviceGroupsData,
} from '@/entities/device-group/types';

import { QueryFilters } from '@/types/services';
import { pathWithQuery } from '@/utils/string';

export const getDeviceGroup = ({ id }: GetDeviceGroupData): Promise<DeviceGroup> =>
  api.get(`/device_groups/${ id }`);

export const searchDeviceGroups = (name: SearchDeviceGroupsData): Promise<DeviceGroup[]> =>
	api.get(`/device_groups/search?query=${name}`);

export const getDeviceGroupTree = (
  filters: QueryFilters<DeviceGroup> | undefined = undefined,
  axiosRequestConfig?: AxiosRequestConfig | undefined,
): Promise<DeviceGroup[]> => api.get(pathWithQuery('/device_groups/tree', filters), { ...axiosRequestConfig });

export const getDeviceGroups = (
  filters: QueryFilters<DeviceGroup> | undefined = undefined,
  axiosRequestConfig?: AxiosRequestConfig | undefined,
): Promise<DeviceGroup[]> => api.get(pathWithQuery('/device_groups', filters), { ...axiosRequestConfig });

export const getRootDeviceGroups = (
  filters: QueryFilters<DeviceGroup> | undefined = undefined,
  axiosRequestConfig?: AxiosRequestConfig | undefined,
): Promise<DeviceGroup[]> => api.get(pathWithQuery('/device_groups/roots', filters), { ...axiosRequestConfig });

export const createDeviceGroup = (data: CreateDeviceGroupData): Promise<DeviceGroup> =>
  api.post('/device_groups', data);

export const updateDeviceGroup = (data: UpdateDeviceGroupData): Promise<DeviceGroup> =>
  api.put(`/device_groups/${ data.id }`, data);

export const deleteDeviceGroup = (data: DeleteDeviceGroupData): Promise<DeviceGroup> =>
  api.delete(`/device_groups/${ data.id }`);

export const createDeviceGroupZip = (payload: CreateDeviceGroupZipData): Promise<DeviceGroup> =>
  api.post(`/device_groups/${ payload.id }/zip_upload`, payload.data);
