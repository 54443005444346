import { ExtractPropTypes, PropType } from 'vue';

import theme from '@/styles/tokens';

export const alignValues = ['start', 'center', 'end', 'stretch'] as const;
export const justifyValues = ['start', 'center', 'end', 'between', 'around'] as const;

export const props = {
  spacing: {
    type: Number as PropType<keyof typeof theme.spacing>,
    default: 3,
  },
  align: { type: String as PropType<typeof alignValues[number]>, default: 'stretch' },
  justify: {
    type: String as PropType<typeof justifyValues[number]>,
    default: 'center',
  },
  as: {
    type: String,
    default: 'div',
  },
};

export type Props = ExtractPropTypes<typeof props>;
