import { Device } from '@/entities/device/types';
import { GetInspectionGroupData } from '@/entities/inspection-group/types';
import { api } from '@/services';
import {
  CreateInspectionData,
  DeleteInspectionData, GetInspectionByInspectionGroupData,
  GetInspectionData, GetInspectionDeviceTreeData,
  Inspection, InspectionDeviceTreeData,
  UpdateInspectionData,
} from '@/entities/inspection/types';

import { QueryFilters } from '@/types/services';
import { pathWithQuery } from '@/utils/string';
import { GetInspectionImageData, InspectionImage } from '@/entities/inspection-image/types';

export const getInspection = ({ id }: GetInspectionData): Promise<Inspection> =>
  api.get(`/inspections/${ id }`);

export const getInspectionDeviceTree = ({ id }: GetInspectionDeviceTreeData): Promise<InspectionDeviceTreeData[]> =>
  api.get(`/inspection-device-tree?inspectionGroup=${ id }`);

export const getInspections = (
  filters: QueryFilters<Inspection> | undefined = undefined,
): Promise<Inspection[]> => api.get(pathWithQuery('/inspections', filters));

export const getInspectionsByInspectionGroup = (data: GetInspectionByInspectionGroupData): Promise<Inspection[]> =>
  api.get(`/inspections/by_inspection_groups`, { params: data });

export const getInspectionImages = (data: GetInspectionImageData): Promise<InspectionImage[]> =>
  api.get(`/inspections/${ data.id }/images`, {params: {'order[y]': 'asc'}});

export const createInspection = (data: CreateInspectionData): Promise<Inspection> =>
  api.post('/inspections', data);

export const updateInspection = (data: UpdateInspectionData): Promise<Inspection> =>
  api.put(`/inspections/${ data.id }`, data);

export const updateInspectionGroupWithInspection = (data: { id: number, inspectionDate: Date, comment: string, inspectedBySupairvision: boolean, weather: number }): Promise<void> =>
  api.post(`/inspection_groups/${data.id}/updateWithInspection`, data)

export const deleteInspection = (data: DeleteInspectionData): Promise<Inspection> =>
  api.delete(`/inspections/${ data.id }`);


export const startInspectionSlicing = ({ id }: GetInspectionData) => api.get(`/inspections/${ id }/slicing`);
