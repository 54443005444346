import { ExtractPropTypes, PropType } from 'vue';

export const sizeValues = ['md', 'sm'];

export const props = {
  as: {
    type: String,
    default: 'div',
  },
  size: {
    type: String as PropType<typeof sizeValues[number]>,
    default: 'md',
  }
}

export type Props = ExtractPropTypes<typeof props>
