import { ExtractPropTypes, PropType } from 'vue';
import type { Properties } from 'csstype';

import theme from '@/styles/tokens';

export const positionValues = ['left', 'center', 'right', 'apart'] as const;
export const directionValues = ['row', 'column'] as const;
export const alignValues = ['flex-start', 'center', 'flex-end', 'stretch'] as const;

export const props = {
  position: {
    type: String as PropType<typeof positionValues[number]>,
    default: 'left',
  },
  noWrap: { type: Boolean, default: false },
  grow: { type: Boolean, default: false },
  spacing: {
    type: Number as PropType<keyof typeof theme.spacing>,
    default: 3,
  },
  direction: {
    type: String as PropType<typeof directionValues[number]>,
    default: 'row',
  },
  align: { type: String as PropType<Properties['alignItems']>, default: 'center' },
  as: {
    type: String,
    default: 'div',
  },
};

export type Props = ExtractPropTypes<typeof props>;
