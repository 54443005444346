import { api } from '@/services';

import {
  AnnotationSeverity,
  CreateAnnotationSeverityData,
  DeleteAnnotationSeverityData,
  GetAnnotationSeverityData,
  UpdateAnnotationSeverityData,
} from '@/entities/annotation-severity/types';

import { QueryFilters } from '@/types/services';
import { pathWithQuery } from '@/utils/string';

export const getAnnotationSeverity = ({
  id,
}: GetAnnotationSeverityData): Promise<AnnotationSeverity> =>
  api.get(`/annotation_severities/${id}`);

export const getAnnotationSeverities = (
  filters: QueryFilters<AnnotationSeverity> | undefined = undefined,
): Promise<AnnotationSeverity[]> => api.get(pathWithQuery('/annotation_severities', filters));

export const createAnnotationSeverity = (
  data: CreateAnnotationSeverityData,
): Promise<AnnotationSeverity> => api.post('/annotation_severities', data);

export const updateAnnotationSeverity = (
  data: UpdateAnnotationSeverityData,
): Promise<AnnotationSeverity> => api.put(`/annotation_severities/${data.id}`, data);

export const deleteAnnotationSeverity = (
  data: DeleteAnnotationSeverityData,
): Promise<AnnotationSeverity> => api.delete(`/annotation_severities/${data.id}`);
