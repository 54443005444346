import { createBreakpoint } from 'styled-components-breakpoint';
import { rem } from 'polished';

export const containers = {
  min: {
    value: 0,
    gutter: 16,
    margin: 0,
  }, // leave min to 0
  sm: {
    value: 576,
    gutter: 16,
    margin: 0,
  },
  md: {
    value: 768,
    gutter: 16,
    margin: 16,
  },
  lg: {
    value: 992,
    gutter: 16,
    margin: 16,
  },
  xl: {
    value: 1200,
    gutter: 16,
    margin: 16,
  },
  xxl: {
    value: 1400,
    gutter: 16,
    margin: 60,
  },
} as const;

export const breakpoint = createBreakpoint(
  Object.fromEntries(Object.entries(containers).map(([key, { value }]) => [key, value])),
);

const tokens = {
  colors: {
    white: {
      DEFAULT: '#FFF',
    },
    black: {
      DEFAULT: '#383838',
    },
    gray: {
      DEFAULT: '#808080',
      300: '#E6E6E6',
      100: '#F0F0F0',
      50: '#FAFAFA',
    },
    primary: {
      DEFAULT: '#FF6D10',
      500: '#B34D0B',
      400: '#D95D0E',
      300: '#FFA770',
      100: '#FFA77033',
      50: '#FFEDE2',
    },
    info: {
      DEFAULT: '#4F66CF',
    },
    success: {
      DEFAULT: '#8AB909',
      400: '#afe123',
      300: '#badf52',
      100: '#c1de71',
    },
    warning: {
      DEFAULT: '#E9D116',
    },
    danger: {
      DEFAULT: '#E24B4B',
      400: '#e15c5c',
      300: '#e17272',
      100: '#e18585',
    },
  },
  screens: Object.fromEntries(
    Object.entries(containers).map(([key, { value }]) => [key, rem(value)]),
  ) as { [key in keyof typeof containers]: string },
  gutter: {
    xs: '.25rem',
    sm: '.5rem',
    md: '1rem',
  },
  spacing: {
    1: '.25rem', // 4px
    2: '.5rem', // 8px
    3: '.75rem', // 12px
    4: '1rem', // 16px
    5: '1.5rem', // 24px
    6: '2rem', // 32px
    7: '2.5rem', // 40px
    8: '3rem', // 48px
    9: '4rem', // 64px
    10: '5rem', // 80px
    11: '6rem', // 96px
    12: '7rem', // 112px
    13: '8rem', // 128px
    14: '9rem', // 144px
    15: '10rem', // 160px
  },
  borderWidth: {
    DEFAULT: '0.063rem',
    '2': '0.125rem',
  },
  borderRadius: {
    none: 0,
    sm: '0.125rem', // 2px
    md: '0.313rem', // 5px
    lg: '0.5rem', // 8px
    DEFAULT: '0.25rem', // 4px
  },
  fontSize: {
    DEFAULT: ['1rem', { lineHeight: '1.362rem', letterSpacing: '0.063rem' }],
    xxl: ['2.813rem', { lineHeight: '2.813rem', letterSpacing: '-0.063rem' }], // 45px
    xl: ['2.25rem', { lineHeight: '3.063rem', letterSpacing: '0.063rem' }], // 36px
    lg: ['1.875rem', { lineHeight: '1.875rem', letterSpacing: '-0.063rem' }], // 30px
    md: ['1.563rem', { lineHeight: '2.128rem', letterSpacing: 'normal' }], // 25px
    sm: ['1.25rem', { lineHeight: '1.703rem', letterSpacing: '0.063rem' }], // 20px
    xs: ['1rem', { lineHeight: '1.362rem', letterSpacing: '0.063rem' }], // 16px
    xxs: ['0.8rem', { lineHeight: '1.089rem', letterSpacing: '0.063rem' }], // 12.8px
    xxs2: ['0.64rem', { lineHeight: '0.871rem', letterSpacing: '0.063rem' }], // 10.24px
  },
  letterSpacing: {
    DEFAULT: '0.063rem', // 1px
  },
  shadow: {
    DEFAULT: '.125rem .125rem .5rem -0.188rem #808080'
  }
} as const;

export default tokens;
