import { Ref, UnwrapNestedRefs } from 'vue';
import { useQuery, useMutation, useQueryClient } from 'vue-query';

import {
  createWeather,
  deleteWeather,
  getWeather,
  getWeathers,
  updateWeather,
} from '@/entities/weather/services';

import {
  GetWeatherData,
  CreateWeatherData,
  UpdateWeatherData,
  DeleteWeatherData,
} from '@/entities/weather/types';

export const weatherQueryName = 'weathers';
export const useGetWeathersQuery = () => useQuery(weatherQueryName, () => getWeathers());

export const useGetWeatherQuery = (data: Ref<GetWeatherData>) =>
  useQuery([weatherQueryName, data], () => getWeather(data.value));

export const useCreateWeatherMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<CreateWeatherData>) => createWeather(data), {
    onSuccess() {
      queryClient.refetchQueries(weatherQueryName, {active: true});
    },
  });
};

export const useUpdateWeatherMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<UpdateWeatherData>) => updateWeather(data), {
    onSuccess({ id }) {
      queryClient.refetchQueries(weatherQueryName, {active: true});
      queryClient.refetchQueries([weatherQueryName, id], {active: true});
    },
  });
};

export const useDeleteWeatherMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: UnwrapNestedRefs<DeleteWeatherData>) => deleteWeather(data), {
    onSuccess() {
      queryClient.refetchQueries(weatherQueryName, {active: true});
    },
  });
};
