import { DropzoneOptions } from 'dropzone';
import { ExtractPropTypes, PropType } from 'vue';

export const props = {
  options: {
    type: Object as PropType<DropzoneOptions>,
    default: () => ({}),
  },
};

export type Props = ExtractPropTypes<typeof props>;
