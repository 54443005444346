import styled from 'vue3-styled-components';
import { rem } from 'polished';
import tokens from '@/styles/tokens';
import { props } from './osd.shared';

const { colors } = tokens;
export const StOSD = styled('div', props)`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;

  .navigator {
    transform: translateX(-100%);
  }
`;

export const StOSDOverlayBackground = styled('div', {
  active: {
    type: Boolean,
    default: false,
  },
  isHighlight: {
    type: Boolean,
    default: false,
  },
})`
  position: relative;
  z-index: 0;
  border: ${ () => rem(2) } dashed ${ () => colors.primary.DEFAULT };
  transition: border-color .3s ease-in-out;

  &::before {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  button {
    opacity: 0;
    transition: opacity .2s ease-in-out;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }

  ${ ({ active }) =>
    active &&
    `
      &:not(.is-zoomed)::before {
        opacity: .5;
      }
    ` }
  ${ ({ isHighlight }) =>
    isHighlight &&
    `
      border-color: ${ colors.info.DEFAULT };
      &:not(.is-zoomed)::before {
        opacity: .5;
      }
    ` }
  &.is-zoomed {
    &::before {
      opacity: 0;
    }
  }
`;
