import { AxiosRequestConfig } from 'axios';
import { Ref, UnwrapNestedRefs } from 'vue';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'vue-query';

import {
  createDeviceGroup,
  updateDeviceGroup,
  getDeviceGroup,
  getDeviceGroups,
  getRootDeviceGroups,
  deleteDeviceGroup, createDeviceGroupZip, getDeviceGroupTree,
} from '@/entities/device-group/services';

import { QueryFilters } from '@/types/services';
import {
  CreateDeviceGroupData,
  UpdateDeviceGroupData,
  DeviceGroup,
  GetDeviceGroupData,
  DeleteDeviceGroupData, CreateDeviceGroupZipData,
} from '@/entities/device-group/types';

import { queryKeyHash } from '@/utils/query';

export const deviceGroupsQueryName = 'deviceGroups';

export const useGetDeviceGroupTree = (
  options?: UseQueryOptions<DeviceGroup[]>,
  filters?: Ref<QueryFilters<DeviceGroup>>,
  axiosRequestConfig?: AxiosRequestConfig,
) =>
  useQuery([deviceGroupsQueryName], () => getDeviceGroupTree(filters?.value, axiosRequestConfig), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
  });


export const useGetRootDeviceGroupsQuery = (
  options?: UseQueryOptions<DeviceGroup[]>,
  filters?: Ref<QueryFilters<DeviceGroup>>,
  axiosRequestConfig?: AxiosRequestConfig,
) =>
  useQuery([`${ deviceGroupsQueryName }-roots`], () => getRootDeviceGroups(filters?.value, axiosRequestConfig), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
  });

export const useGetDeviceGroupsQuery = (
  options?: UseQueryOptions<DeviceGroup[]>,
  filters?: Ref<QueryFilters<DeviceGroup>>,
  axiosRequestConfig?: AxiosRequestConfig,
) =>
  useQuery(deviceGroupsQueryName, () => getDeviceGroups(filters?.value, axiosRequestConfig), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
  });

export const useGetDeviceGroupQuery = (
  data: Ref<GetDeviceGroupData>,
  options: UseQueryOptions<DeviceGroup> = {},
) => useQuery([deviceGroupsQueryName, data], () => getDeviceGroup(data.value), options);

export const useCreateDeviceGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<CreateDeviceGroupData>) => createDeviceGroup(data), {
    onSuccess() {
      queryClient.refetchQueries(deviceGroupsQueryName, { active: true });
      queryClient.refetchQueries(`${ deviceGroupsQueryName }-roots`, { active: true });
    },
  });
};

export const useDeleteDeviceGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<DeleteDeviceGroupData>) => deleteDeviceGroup(data), {
    onSuccess() {
      queryClient.refetchQueries(deviceGroupsQueryName, { active: true });
      queryClient.refetchQueries(`${ deviceGroupsQueryName }-roots`, { active: true });
    },
  });
};

export const useUpdateDeviceGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<UpdateDeviceGroupData>) => updateDeviceGroup(data), {
    onSuccess({ id }) {
      queryClient.refetchQueries(deviceGroupsQueryName, { active: true });
      queryClient.refetchQueries([deviceGroupsQueryName, { id }], { active: true });
    },
  });
};
export const useCreateDeviceGroupZipMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<CreateDeviceGroupZipData>) => createDeviceGroupZip(data), {
    onSuccess({ id }) {
      queryClient.refetchQueries([deviceGroupsQueryName, { id }], { active: true });
      queryClient.refetchQueries(deviceGroupsQueryName, { active: true });
    },
  });
};
