import tokens from '@/styles/tokens';

const { colors, borderRadius } = tokens;

export default `
  .el-cascader {
    .el-input__wrapper {
      border: 1px solid ${ colors.primary.DEFAULT };
      border-radius: ${ borderRadius.lg };
      box-shadow: none;
    }

    &::hover {
      .el-input__wrapper {
        box-shadow: none!important;
      }
    }

    &[aria-describedby] {
      .el-input__wrapper {
        box-shadow: none!important;
      }
    }
  }

  .el-cascader-panel {
    --el-cascader-menu-selected-text-color: ${ colors.primary.DEFAULT };
    .el-checkbox {
      --el-checkbox-checked-bg-color: ${ colors.primary.DEFAULT };
      --el-checkbox-checked-input-border-color: ${ colors.primary.DEFAULT };
      --el-checkbox-input-border-color-hover: ${ colors.primary.DEFAULT };
    }
  }
`;
