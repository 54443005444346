import tokens from '@/styles/tokens';
import { rem } from 'polished';
import styled from 'vue3-styled-components';
import { props } from './ControlDrawer.shared';

export const StControlDrawer = styled('div', props)`
  .controlDrawer {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translateX(calc(100% - ${ () => rem(40) }));
    transition: transform .2s ease-in-out;
    width: 50%;
    .controlDrawer__reference__bar {
      display: flex;
      align-items: center;
      width: ${ () => rem(40) };
      background-color: ${ () => tokens.colors.gray['100'] };
      height: 100%;
      .controlDrawer__reference__bar__ctrl {
        left: 0;
        transform: translateX(-50%);
      }
    }
    .controlDrawer__content {
      flex: 1 1 0%;
    }

    &.is-open {
      transform: translateX(0%);
    }
  }
`;
