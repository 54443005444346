import { ref, Ref, UnwrapNestedRefs } from 'vue';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'vue-query';

import {
  createVoltaMeasure,
  deleteVoltaMeasure,
  getVoltaMeasure,
  getVoltaMeasures,
  updateVoltaMeasure,
  uploadUpdateVoltaMeasure,
} from '@/entities/volta-measure/services';

import {
  VoltaMeasure,
  GetVoltaMeasureData,
  CreateVoltaMeasureData,
  UpdateVoltaMeasureData,
  DeleteVoltaMeasureData,
  UploadUpdateVoltaMeasureData,
} from '@/entities/volta-measure/types';

import { QueryFilters } from '@/types/services';
import { queryKeyHash } from '@/utils/query';

export const voltaMeasuresQueryName = 'voltaMeasures';

export const useGetVoltaMeasuresQuery = (
  options?: UseQueryOptions<VoltaMeasure[]>,
  filters?: Ref<QueryFilters<VoltaMeasure>>,
) =>
  useQuery(voltaMeasuresQueryName, () => getVoltaMeasures(filters?.value), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
  });

export const useGetVoltaMeasureQuery = (
  data: Ref<GetVoltaMeasureData>,
  options?: UseQueryOptions<VoltaMeasure>,
) => useQuery([voltaMeasuresQueryName, data], () => getVoltaMeasure(data.value), options);

export const useCreateVoltaMeasureMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<CreateVoltaMeasureData>) => createVoltaMeasure(data), {
    onSuccess(data) {
      queryClient.refetchQueries([voltaMeasuresQueryName, { id: data.id }], {active: true});
    },
  });
}

export const useUpdateVoltaMeasureMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<UpdateVoltaMeasureData>) => updateVoltaMeasure(data), {
    onSuccess({ id }) {
      queryClient.refetchQueries(voltaMeasuresQueryName, {active: true});
      queryClient.refetchQueries([voltaMeasuresQueryName, id], {active: true});
    },
  });
};

export const useDeleteVoltaMeasureMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<DeleteVoltaMeasureData>) => deleteVoltaMeasure(data), {
    onSuccess() {
      queryClient.refetchQueries(voltaMeasuresQueryName, {active: true});
    },
  });
};

export const useUploadUpdateVoltaMeasure = () => {
  const queryClient = useQueryClient()
  return useMutation((data : UnwrapNestedRefs<UploadUpdateVoltaMeasureData>) => uploadUpdateVoltaMeasure(data), {
    onSuccess({ id }) {
      queryClient.refetchQueries(voltaMeasuresQueryName, {active: true});
      queryClient.refetchQueries([voltaMeasuresQueryName, id], {active: true});
    }
  })
}