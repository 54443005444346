import { ExtractPropTypes, PropType } from 'vue';

export const sizeValues = ['md', 'sm'] as const;

export const props = {
  as: {
    type: String,
    default: 'div',
  },
  size: {
    type: String as PropType<typeof sizeValues[number]>,
    default: 'md',
  },
  title: {
    type: [String, Number],
    default: '',
  },
  subtitle: {
    type: [String, Number],
    default: '',
  },
  color: {
    type: String,
    default: '',
  }
};

export type Props = ExtractPropTypes<typeof props>
