import { fabric } from 'fabric';
import { ComponentInternalInstance, getCurrentInstance } from 'vue';

const getParentFabricGroup = (
  instance?: ComponentInternalInstance | null,
): ComponentInternalInstance | null =>
  // eslint-disable-next-line no-nested-ternary
  instance?.parent
    ? instance?.parent.type.name === 'FabricGroup'
      ? instance?.parent
      : getParentFabricGroup(instance?.parent)
    : null;

type Obj = fabric.Object | fabric.Group | null;
type RenderObjectOptions<O extends Obj> = {
  object: O;
  canvas?: fabric.Canvas;
  vm: ReturnType<typeof getCurrentInstance>;
  deferredObject: any;
};
export const renderObject = <O extends Obj>({
  object,
  canvas,
  vm,
  deferredObject,
}: RenderObjectOptions<O>): O => {
  const parentGroup = getParentFabricGroup(vm);
  if (parentGroup) {
    parentGroup?.exposed?.objects.value.push(deferredObject.promise);
  } else if (canvas && object) {
    canvas.add(object);
  }
  return object;
};
