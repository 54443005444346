import { ExtractPropTypes } from 'vue';

export const props = {
  modelValue: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: Infinity,
  },
  min: {
    type: Number,
    default: -Infinity,
  },
  step: {
    type: Number,
    default: 1,
  },
  float: {
    type: Boolean,
    default: false,
  }
};

export type Props = ExtractPropTypes<typeof props>;
