import tokens from '@/styles/tokens';

const { colors } = tokens;

export default `
  .draggable-ghost {
    background-color: ${colors.gray['100']};
    * {
      opacity: 0;
    }
  }
`;
