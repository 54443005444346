export const props = {
  as: {
    type: String,
    default: 'div',
  },
  title: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
}
