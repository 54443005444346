import { clearAuthTokens, setAuthTokens } from 'axios-jwt';
import { api } from '@/services';
import { LoginData, LoginResponse } from '@/entities/security/types';

export { getAccessToken, getRefreshToken } from 'axios-jwt';

export const login = async (params: LoginData): Promise<LoginResponse> =>
  api.post<LoginData, LoginResponse>('/login_check', params).then((response) => {
    const { token: accessToken, refresh_token: refreshToken } = response;
    setAuthTokens({
      accessToken,
      refreshToken,
    });
    return Promise.resolve(response);
  });

export const logout = () => clearAuthTokens();
