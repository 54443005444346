import { ExtractPropTypes, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';

export type BreadcrumbLink = {
  name: string;
  to: RouteLocationRaw | null;
  sublinks?: BreadcrumbLink[];
};

export const props = {
  as: {
    type: String,
    default: 'div',
  },
  links: {
    type: Array as PropType<BreadcrumbLink[]>,
    default: () => [],
  },
};

export type Props = ExtractPropTypes<typeof props>;
