import { api } from '@/services';
import {
  CreateInspectionImageData,
  DeleteInspectionImageData,
  GetInspectionImageData,
  InspectionImage,
  UpdateInspectionImageData,
} from '@/entities/inspection-image/types';

export const getInspectionImage = ({ id }: GetInspectionImageData): Promise<InspectionImage> =>
  api.get(`/inspection_images/${id}`);

export const getInspectionImages = (): Promise<InspectionImage[]> => api.get('/inspection_images');

export const createInspectionImage = (data: CreateInspectionImageData): Promise<InspectionImage> =>
  api.post('/inspection_images', data);

export const updateInspectionImage = (data: UpdateInspectionImageData): Promise<InspectionImage> =>
  api.put(`/inspection_images/${data.id}`, data);

export const deleteInspectionImage = (data: DeleteInspectionImageData): Promise<InspectionImage> =>
  api.delete(`/inspection_images/${data.id}`);
