import { Ref } from 'vue';
import { QueryFilters } from '@/types/services';

import {
  useMutation,
  useQuery,
  UseQueryOptions,
} from 'vue-query';

import { queryKeyHash } from '@/utils/query';

import {
  getAnnotationImage,
  getAnnotationImages,
  uploadAnnotationImage,
} from '@/entities/annotation-image/services';

import {
  AnnotationImage,
  GetAnnotationImageData,
  UploadAnnotationImageData,
} from '@/entities/annotation-image/types';

export const annotationImagesQueryName = 'annotation-images';

export const useGetAnnotationImageQuery = (
  data: Ref<GetAnnotationImageData>,
  options?: UseQueryOptions<AnnotationImage>,
) => useQuery([annotationImagesQueryName, data], () => getAnnotationImage(data.value), options);

export const useGetAnnotationImagesQuery = (
  options?: UseQueryOptions<AnnotationImage[]>,
  filters?: Ref<QueryFilters<AnnotationImage>>,
) => useQuery(annotationImagesQueryName, () => getAnnotationImages(filters?.value), {
  ...options,
  queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
});

export const useUploadAnnotationImageMutation = () => useMutation((data: UploadAnnotationImageData) => uploadAnnotationImage(data));
