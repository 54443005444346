import { useMutation, useQuery, useQueryClient } from 'vue-query';
import {
  createInspectionImage,
  deleteInspectionImage,
  getInspectionImage,
  getInspectionImages,
  updateInspectionImage,
} from '@/entities/inspection-image/services';
import {
  CreateInspectionImageData,
  DeleteInspectionImageData,
  GetInspectionImageData,
  UpdateInspectionImageData,
} from '@/entities/inspection-image/types';
import { Ref, UnwrapNestedRefs } from 'vue';

export const inspectionImagesQueryName = 'inspectionImages';
export const useGetInspectionImagesQuery = () =>
  useQuery(inspectionImagesQueryName, () => getInspectionImages());

export const useGetInspectionImageQuery = (data: Ref<GetInspectionImageData>) =>
  useQuery([inspectionImagesQueryName, data], () => getInspectionImage(data.value));

export const useCreateInspectionImageMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<CreateInspectionImageData>) => createInspectionImage(data),
    {
      onSuccess() {
        queryClient.refetchQueries(inspectionImagesQueryName, {active: true});
      },
    },
  );
};

export const useUpdateInspectionImageMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<UpdateInspectionImageData>) => updateInspectionImage(data),
    {
      onSuccess({ id }) {
        queryClient.refetchQueries(inspectionImagesQueryName, {active: true});
        queryClient.refetchQueries([inspectionImagesQueryName, id], {active: true});
      },
    },
  );
};

export const useDeleteInspectionImageMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<DeleteInspectionImageData>) => deleteInspectionImage(data),
    {
      onSuccess({ id }, data) {
        queryClient.refetchQueries(inspectionImagesQueryName);
      },
    },
  );
};
