import { ExtractPropTypes, PropType } from 'vue';
import tokens from '@/styles/tokens';

export const props = {
  name: {
    type: String,
    required: true,
  },
  size: {
    type: Number as PropType<keyof typeof tokens.spacing>,
    default: 5,
  },
};

export type Props = ExtractPropTypes<typeof props>;
