
export const useForm = (emit: any) => {
  function onSubmitted(...args: any[]) {
    emit('submitted', ...args);
  }

  return {
    onSubmitted,
  };
};
