import { useMutation } from 'vue-query';
import { LoginData } from '@/entities/security/types';
import { UnwrapNestedRefs } from 'vue';
import { login } from '@/entities/security/services';
import { useRouter } from 'vue-router';

export const useLoginMutation = () => {
  const router = useRouter();
  return useMutation((data: UnwrapNestedRefs<LoginData>) => login(data), {
    onSuccess: () => {
      router.push('/');
    },
  });
};
