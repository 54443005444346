import tokens from '@/styles/tokens';

const { colors, spacing, borderRadius } = tokens;

export default `
  .nk-context-menu.nk-context-menu-wrapper {
    box-shadow: 2px 2px 8px -3px ${ colors.gray.DEFAULT };
    padding: ${ spacing['2'] } 0;
    background-color: white;
    border-radius: ${ borderRadius.lg }
    li {
      background-color: white;
      padding: ${ spacing['2'] } ${ spacing['4'] };
      &:hover {
        cursor: pointer;
        background-color: ${ colors.gray['100'] }
      }
    }
  }
`;
