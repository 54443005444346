import { api } from '@/services';
import {
  CreateAttributeData,
  DeleteAttributeData,
  GetAttributeData,
  Attribute,
  UpdateAttributeData,
} from '@/entities/attribute/types';

export const getAttribute = ({ id }: GetAttributeData): Promise<Attribute> =>
  api.get(`/attributs/${id}`);

export const getAttributes = (): Promise<Attribute[]> => api.get('/attributs');

export const createAttribute = (data: CreateAttributeData): Promise<Attribute> =>
  api.post('/attributs', data);

export const updateAttribute = (data: UpdateAttributeData): Promise<Attribute> =>
  api.put(`/attributs/${data.id}`, data);

export const deleteAttribute = (data: DeleteAttributeData): Promise<Attribute> =>
  api.delete(`/attributs/${data.id}`);
