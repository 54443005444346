import tokens from '@/styles/tokens';
import styled from 'vue3-styled-components';
import { props } from './profile.shared';

export const StProfile = styled('div', props)`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: ${() => tokens.spacing['2']};

  span {
    display: block;
    color: ${() => tokens.colors.black.DEFAULT};
    letter-spacing: ${() => tokens.letterSpacing.DEFAULT};
  }
`;
