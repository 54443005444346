import { ExtractPropTypes, Ref } from 'vue';
import { fabric } from 'fabric';

export const injectionKey = Symbol('');
export type FabricExposition = {
  getCanvas: () => fabric.Canvas;
  isHoldingSpace: Ref<boolean>;
};
export type AssemblerRootExposition = {
  centerView: () => void
}
export type GroupExposition = {
  objects: Ref<any[]>;
  deferredObject: any;
};

export const commonProps = {
  position: {
    type: Number,
    default: 0,
  },
  _fabric: {
    type: Boolean,
    default: true,
  },
};

export type CommonProps = ExtractPropTypes<typeof commonProps>;
