import styled from 'vue3-styled-components';
import tokens from '@/styles/tokens';
import { props } from './textarea.shared';

const { spacing, colors, borderRadius, borderWidth } = tokens;

export const StTextarea = styled('textarea', props)`
  height: calc(${() => spacing['7']} * 2);
  border: ${() => borderWidth.DEFAULT} solid ${() => colors.primary.DEFAULT};
  border-radius: ${() => borderRadius.DEFAULT};
  background-color: white;
  padding: ${() => spacing['3']};
  outline: 0;

  ${({ error }) => error && `border-color: ${colors.danger.DEFAULT};`}

  &:focus {
    border: ${() => borderWidth['2']} solid ${() => colors.primary.DEFAULT};
  }
`;
