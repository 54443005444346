import * as sc from 'vue3-styled-components';
import draggableStyle from '@/styles/plugins/draggable.styled';
import filePondStyle from '@/styles/plugins/file-pond.styled';
import moveableStyle from './plugins/moveable.styled';
import contextMenuStyle from './plugins/context-menu.styled';
import deviceSvgHighlight from './plugins/device-svg-highlight.styled';
import cascader from './plugins/Element-plus/cascader.styled';

(() => (sc as any).injectGlobal`
  ${ moveableStyle }
  ${ draggableStyle }
  ${ filePondStyle }
  ${ contextMenuStyle }
  ${ deviceSvgHighlight }
  ${ cascader }
`)();
