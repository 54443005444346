import tokens from '@/styles/tokens';

const { colors, spacing } = tokens;

export default `
  .openseadragon-container {
    .osd-layer[data-index] {
      z-index: attr(data-index);
    }
  }
  .moveable-control-box {
    z-index: 0!important;
    .moveable-line,
    .moveable-origin {
      display: none;
    }
    .moveable-control.moveable-direction {
      border-color: ${colors.primary.DEFAULT};
      background-color: ${colors.primary.DEFAULT};
      width: ${spacing['3']};
      height: ${spacing['3']};
      border-width: 0;
      transition: background-color 0.3s ease-in-out;
    }

    &[data-highlight="true"] {
      .moveable-control.moveable-direction {
        background-color: ${colors.info.DEFAULT};
        border-color: ${colors.info.DEFAULT};
      }
    }
  }
`;
