import { QueryFilters } from '@/types/services';
import { queryKeyHash } from '@/utils/query';
import { useQuery, useMutation, useQueryClient, UseQueryOptions } from 'vue-query';
import { Ref, UnwrapNestedRefs } from 'vue';

import { createService, deleteService, getService, getServices, updateService } from '@/entities/services/services';

import {
  GetServiceData,
  CreateServiceData,
  UpdateServiceData,
  DeleteServiceData,
  Service,
} from '@/entities/services/types';

export const serviceQueryName = 'services';
export const useGetServicesQuery = (
  options: UseQueryOptions<Service[]> = {},
  filters?: Ref<QueryFilters<Service>>
) => useQuery(serviceQueryName, () => getServices(filters?.value), {
  ...options,
  queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
});

export const useGetServiceQuery = (
  data: Ref<GetServiceData>,
  options: UseQueryOptions<Service>,
) =>
  useQuery([serviceQueryName, data], () => getService(data.value), options);

export const useCreateServiceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<CreateServiceData>) => createService(data), {
    onSuccess() {
      queryClient.refetchQueries(serviceQueryName, {active: true});
    },
  });
};

export const useUpdateServiceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<UpdateServiceData>) => updateService(data), {
    onSuccess({ id }) {
      queryClient.refetchQueries(serviceQueryName, {active: true});
      queryClient.refetchQueries([serviceQueryName, id], {active: true});
    },
  });
};

export const useDeleteServiceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: UnwrapNestedRefs<DeleteServiceData>) => deleteService(data), {
    onSuccess() {
      queryClient.refetchQueries(serviceQueryName, {active: true});
    },
  });
};
