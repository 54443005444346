import { useQuery, useMutation, useQueryClient, UseQueryOptions } from 'vue-query';
import {
  createAttribute,
  deleteAttribute,
  getAttribute,
  getAttributes,
  updateAttribute,
} from '@/entities/attribute/services';
import {
  Attribute,
  CreateAttributeData,
  DeleteAttributeData,
  GetAttributeData,
  UpdateAttributeData,
} from '@/entities/attribute/types';
import { Ref, UnwrapNestedRefs } from 'vue';

export const attributesQueryName = 'attributes';
export const useGetAttributesQuery = () => useQuery(attributesQueryName, () => getAttributes());

export const useGetAttributeQuery = (data: Ref<GetAttributeData>, options: UseQueryOptions<Attribute>) =>
  useQuery([attributesQueryName, data], () => getAttribute(data.value), options);

export const useCreateAttributeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<CreateAttributeData>) => createAttribute(data), {
    onSuccess() {
      queryClient.refetchQueries(attributesQueryName, {active: true});
    },
  });
};

export const useUpdateAttributeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<UpdateAttributeData>) => updateAttribute(data), {
    onSuccess({ id }) {
      queryClient.refetchQueries(attributesQueryName, {active: true});
      queryClient.refetchQueries([attributesQueryName, id], {active: true});
    },
  });
};

export const useDeleteAttributeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<DeleteAttributeData>) => deleteAttribute(data), {
    onSuccess() {
      queryClient.refetchQueries(attributesQueryName, {active: true});
    },
  });
};
