import { api } from '@/services';

import { QueryFilters } from '@/types/services';

import {
  CreateServiceData,
  DeleteServiceData,
  GetServiceData,
  Service,
} from '@/entities/services/types';
import { pathWithQuery } from '@/utils/string';

export const getService = ({ id }: GetServiceData): Promise<Service> =>
  api.get(`/services/${ id }`);

export const getServices = (filters: QueryFilters<Service>): Promise<Service[]> =>
  api.get(pathWithQuery('/services', filters));

export const createService = (data: CreateServiceData): Promise<Service> =>
  api.post('/services', data);

export const updateService = (data: Service): Promise<Service> =>
  api.put(`/services/${ data.id }`, data);

export const deleteService = (data: DeleteServiceData): Promise<Service> =>
  api.delete(`/services/${ data.id }`);
