import { ExtractPropTypes, PropType } from 'vue';

export const typeValues = ['primary', 'primary-light', 'success', 'danger', 'warning', 'info'] as const;

export const props = {
  as: {
    type: String,
    default: 'span',
  },
  color: {
    type: String,
    default: '',
  },
  type: {
    type: String as PropType<typeof typeValues[number]>,
    default: 'primary',
  },
  visible: {
    type: Boolean,
    default: true,
  }
};

export type Props = ExtractPropTypes<typeof props>
