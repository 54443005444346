import styled from 'vue3-styled-components';
import 'element-plus/es/components/date-picker/style/css';

import tokens from '@/styles/tokens';

const { spacing, colors } = tokens;

export const StDatePicker = styled('span')`
  .el-date-picker {
    --el-datepicker-active-color: ${() => colors.primary.DEFAULT}
  }

  .el-input {
    --el-input-border-color: ${() => colors.primary.DEFAULT};
    --el-input-hover-border-color: ${() => colors.primary['400']};
    --el-input-focus-border-color: ${() => colors.primary['400']};
  }

  .el-date-editor.el-input {
    height: ${() => spacing['7']};
  }

  .el-input__wrapper {
    padding-left: ${() => spacing['3']};
    padding-right: ${() => spacing['3']};
  }
`;
