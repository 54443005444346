import { useQuery } from 'vue-query';
import { flatten } from 'lodash';
import { isRef, ref, Ref, watchEffect } from 'vue';

export type PaginatedResponseMeta = {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
}

export type PaginatedResponseData<T> = {
  attributes: T;
  id: string;
  type: string;
}

export type PaginatedResponse<T> = {
  data: PaginatedResponseData<T>[];
  meta: PaginatedResponseMeta;
};

const reformatApplicationVndResponse = (i: any | undefined) => {
  const object: any = {};

  if (i) {
    Object.entries(i).forEach(([key, value]) => {
      if (['weathers'].includes(key)) {
        object[key] = (value as any).map((w: any) => w.data.attributes);
        return value;
      }

      if (typeof value === 'object' && !Array.isArray(value)) {
        object[key] = (value as any)?.data?.attributes || value;
        return value;
      }

      if (typeof value === 'object' && Array.isArray(value)) {
        object[key] = value.map(o => o?.data?.attributes ? reformatApplicationVndResponse(o.data.attributes) : o);
        return value;
      }

      object[key] = value;
      return value;
    });

    return object;
  }

  return undefined;
};

export type usePaginatedResponseType<T = any> = (value: Ref<PaginatedResponse<T>>) => { data: Ref<T[]>, meta: Ref<PaginatedResponseMeta> };
export const usePaginatedResponse: usePaginatedResponseType = (value) => {
  const data: Ref = ref([]);
  const meta: Ref = ref({
    currentPage: 0,
    itemsPerPage: 1,
    totalItems: 0,
  });

  function assignData() {
    data.value = value.value?.data?.map(o => reformatApplicationVndResponse(o.attributes)) || data.value;
    meta.value = value.value?.meta || meta.value;
  }

  if (isRef(value)) {
    watchEffect(assignData);
  } else {
    assignData();
  }

  return { data, meta };
};

export const stringifyKey = (key: Parameters<typeof useQuery>[0]): string => {
  if (isRef(key)) return JSON.stringify(key.value);
  return JSON.stringify(key);
};

export const queryKeyHash = <T extends Parameters<typeof useQuery>[0]>(
  key: T,
  ...rest: any
): string =>
  `# [${ flatten([key, ...rest])
    .map(stringifyKey)
    .join(',') }]`;
