import { ExtractPropTypes } from 'vue';

export const props = {
  as: {
    type: String,
    default: 'div',
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  }
};

export type Props = ExtractPropTypes<typeof props>
