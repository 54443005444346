import tokens from '@/styles/tokens';

export const UPDATE_MODEL_EVENT = 'update:modelValue';

export const DEFAULT_COLORS = {
  primary: tokens.colors.primary.DEFAULT,
  'primary-light': tokens.colors.primary['300'],
  warning: tokens.colors.warning.DEFAULT,
  success: tokens.colors.success.DEFAULT,
  danger: tokens.colors.danger.DEFAULT,
  info: tokens.colors.info.DEFAULT,
  gray: tokens.colors.gray.DEFAULT,
} as const;
