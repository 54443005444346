import { Module } from 'vuex';

import { User } from '@/entities/user/types';

export type ConnectionStore = {
  user: User | null,
}

const highestRoles = ['ROLE_ADMIN', 'ROLE_RESPONSIBLE'];

export const connectionStore: Module<ConnectionStore, null> = {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    user: (state) => state.user,
    userGrants: (state) => [...(state.user?.roles || []), ...(state.user?.permissions || [])],
    isGranted: (state, getters) => (permissions: User['permissions'] = [], strict = false) => {
      if (!getters.userGrants || !permissions) {
        return false;
      }

      if (highestRoles.every(o => !permissions.includes(o)) && state.user?.roles?.some(o => highestRoles.includes(o))) {
        return true;
      }

      if (strict) {
        return permissions.every(o => getters.userGrants.includes(o));
      }

      return permissions.some(o => getters.userGrants.includes(o));
    },
  },
  actions: {
    setUser: ({ state }, user) => {
      state.user = user;
    },
  },
};
