import { RouteRecordRaw } from 'vue-router';

import { shouldNotBeLoggedMiddleware } from '@/router/middlewares';

import NkLogin from '@/teams/security/views/NkLogin.vue';
import NkInvitationVerify from '@/teams/security/views/NkInvitationVerify.vue';
import NkForgottenPassword from '@/teams/security/views/NkForgottenPassword.vue';
import NkResetPassword from '@/teams/security/views/NkResetPassword.vue';
import NkUserProfile from '@/teams/security/views/NkUserProfile.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'security-login',
    props: true,
    component: NkLogin,
    meta: {
      middlewares: [shouldNotBeLoggedMiddleware],
    },
  },
  {
    path: '/forgotten-password',
    name: 'security-forgotten-password',
    component: NkForgottenPassword,
    meta: {
      middlewares: [shouldNotBeLoggedMiddleware],
    },
  },
  {
    path: '/reset-password/:token',
    name: 'security-reset-password',
    component: NkResetPassword,
    meta: {
      middlewares: [shouldNotBeLoggedMiddleware],
    },
  },
  {
    path: '/invitation-verify/:token',
    name: 'security-verify',
    component: NkInvitationVerify,
    meta: {
      middlewares: [shouldNotBeLoggedMiddleware],
    },
  },
];

export default routes;
