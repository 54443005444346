import { ExtractPropTypes, PropType } from 'vue';

export const themeValues = ['default', 'primary'] as const;

export const props = {
  modelValue: {
    type: [String, Number, Object, Boolean],
  },
  theme: {
    type: String as PropType<typeof themeValues[number]>,
    default: 'default',
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  filterable: {
    type: Boolean,
    default: false,
  },
  valueKey: {
    type: String,
    default: '',
  },
};

export type Props = ExtractPropTypes<typeof props>
