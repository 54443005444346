import { api } from '@/services';
import { QueryFilters } from '@/types/services';
import { pathWithQuery } from '@/utils/string';

import { Annotation } from '@/entities/annotation/types';

import {
  AnnotationImage,
  GetAnnotationImageData,
  UploadAnnotationImageData,
} from '@/entities/annotation-image/types';

export const getAnnotationImage = ({ id }: GetAnnotationImageData): Promise<AnnotationImage> =>
  api.get(`/annotation_images/${ id }`);

export const getAnnotationImages = (
  filters: QueryFilters<AnnotationImage> | undefined = undefined,
): Promise<AnnotationImage[]> => api.get(pathWithQuery('/annotation_images', filters));

export const uploadAnnotationImage = ({
  id,
  files,
}: UploadAnnotationImageData): Promise<Annotation> =>
  api.post(`/annotations/${ id }/upload`, files);
