<template>
  <StProfile v-bind="$props">
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 10C20 12.7625 17.7625 15 15 15C12.2375 15 10 12.7625 10 10C10 7.2375 12.2375 5 15 5C17.7625 5 20 7.2375 20 10ZM17.4995 10C17.4995 8.62501 16.3745 7.50001 14.9995 7.50001C13.6245 7.50001 12.4995 8.62501 12.4995 10C12.4995 11.375 13.6245 12.5 14.9995 12.5C16.3745 12.5 17.4995 11.375 17.4995 10ZM15 16.2498C11.6625 16.2498 5 17.9248 5 21.2498V23.7498C5 24.4373 5.5625 24.9998 6.25 24.9998H23.75C24.4375 24.9998 25 24.4373 25 23.7498V21.2498C25 17.9248 18.3375 16.2498 15 16.2498ZM7.49947 21.2623V22.4998H22.4995V21.2498C22.2495 20.3623 18.3745 18.7498 14.9995 18.7498C11.6245 18.7498 7.74947 20.3623 7.49947 21.2623Z"
        fill="#383838"
      />
    </svg>
    <div class="flex" :class="{
      'flex-row gap-2': props.inline,
      'flex-col': !props.inline,
    }">
      <span v-if="getData?.name">{{ getData.name }}</span>
      <span v-if="getData?.date">{{ $format(getData.date, props.dateFormat) }}</span>
    </div>
  </StProfile>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { props as propsDefinition } from './profile.shared';
import { StProfile } from './profile.styled';

const props = defineProps(propsDefinition);

const getData = computed(() => props.data);
</script>
