<template>
  <StButton
    ref="button"
    :as="as"
    :aria-disabled="props.disabled"
    v-bind="{...$props, isDisabled}"
  >
    <template v-if="props.icon && !props.rightIcon">
      <NkIcon
        :size="buttonIconSizeBySize"
        :name="props.icon"
      />
    </template>
    <slot />
    <template v-if="props.icon && props.rightIcon">
      <NkIcon
        :size="buttonIconSizeBySize"
        :name="props.icon"
      />
    </template>
  </StButton>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue';
import { NkIcon } from '@/core/components';
import { StButton } from './button.styled';
import { props as propsDefinition } from './button.shared';

const props = defineProps(propsDefinition);

const buttonIconSizeBySize = computed(() => {
  const sizes = {
    xxs: 2,
    xs: 3,
    sm: 4,
    md: 4,
    lg: 5,
  };

  return sizes[props.size];
});

const button = ref(null);

const isDisabled = computed(() => props.disabled);
</script>
