import { Device } from '@/entities/device/types';
import { api } from '@/services';
import { CreateTypeData, DeleteTypeData, GetTypeData, Type } from '@/entities/type/types';
import { QueryFilters } from '@/types/services';
import { pathWithQuery } from '@/utils/string';

export const getType = ({ id }: GetTypeData): Promise<Type> => api.get(`/types/${id}`);

export const getTypeDeviceModel = ({ id }: GetTypeData, wantAttributeId = false): Promise<Device> =>
  api.get(
    `/types/${id}/generate_model_device${
      wantAttributeId ? `?wantAttributeId=${wantAttributeId}` : ''
    }`,
  );

export const getTypes = (filters: QueryFilters<Type> | undefined = undefined): Promise<Type[]> =>
  api.get(pathWithQuery('/types', filters));

export const createType = (data: CreateTypeData): Promise<Type> => api.post('/types', data);

export const updateType = (data: Type): Promise<Type> => api.put(`/types/${data.id}`, data);

export const deleteType = (data: DeleteTypeData): Promise<Type> => api.delete(`/types/${data.id}`);
