import { api } from '@/services';
import {
  AnnotationType,
  CreateAnnotationTypeData,
  DeleteAnnotationTypeData,
  GetAnnotationTypeData,
  UpdateAnnotationTypeData,
} from '@/entities/annotation-type/types';
import { QueryFilters } from '@/types/services';
import { pathWithQuery } from '@/utils/string';
import { AxiosRequestConfig } from 'axios';

export const getAnnotationType = ({ id }: GetAnnotationTypeData): Promise<AnnotationType> =>
  api.get(`/annotation_types/${id}`);

export const getAnnotationTypes = (
  filters: QueryFilters<AnnotationType> | undefined = undefined,
  axiosOptions: AxiosRequestConfig = {},
): Promise<AnnotationType[]> => api.get(pathWithQuery('/annotation_types', filters), { ...axiosOptions });

export const createAnnotationType = (data: CreateAnnotationTypeData): Promise<AnnotationType> =>
  api.post('/annotation_types', data);

export const updateAnnotationType = (data: UpdateAnnotationTypeData): Promise<AnnotationType> =>
  api.put(`/annotation_types/${data.id}`, data);

export const deleteAnnotationType = (data: DeleteAnnotationTypeData): Promise<AnnotationType> =>
  api.delete(`/annotation_types/${data.id}`);
