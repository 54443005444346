import axios from 'axios';

export const ITEM_PER_PAGE = 30;
export const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export const getDomainPath = (path: string) => {
  if (!path) {
    // if (process.env.NODE_ENV === 'development') {
    //   return 'https://openseadragon.github.io/example-images/highsmith/highsmith.dzi';
    // }

    return '';
  }

  return `${ process.env.VUE_APP_BACKEND_URL }/${ path }`;
};
