import { format as _format } from 'date-fns';
import { enGB } from 'date-fns/locale';

export type FormatDateInjection = (date: Date|string, format?: string) => Date

export const formatDate = (date: Date | string = new Date(), format = 'iii, MMM dd yyyy') => {
  const dateEl = typeof date === 'string' ? new Date(date) : date;

  return _format(dateEl, format, {
    locale: enGB,
  });
};
