import axios from 'axios';
import {
  applyAuthTokenInterceptor,
  clearAuthTokens,
  IAuthTokens,
  TokenRefreshRequest,
} from 'axios-jwt';
import { redirectToLogin } from '@/router';
import { api } from '@/services';
import { LoginResponse } from '@/entities/security/types';

// Interceptors
api.interceptors.response.use((response) => response.data);

const requestRefresh: TokenRefreshRequest = async (
  refreshToken: string,
): Promise<IAuthTokens | string> => {
  try {
    const response = await axios.post<LoginResponse>(
      `${process.env.VUE_APP_API_URL}/refresh_token`,
      { refresh_token: refreshToken },
    );
    return response.data.token;
  } catch (e) {
    clearAuthTokens();
    redirectToLogin();
    return 'n/a';
  }
};

applyAuthTokenInterceptor(api, { requestRefresh });
