import { api } from '@/services';

import {
  CreateWeatherData,
  DeleteWeatherData,
  GetWeatherData,
  Weather,
  UpdateWeatherData,
} from '@/entities/weather/types';

export const getWeather = ({ id }: GetWeatherData): Promise<Weather> => api.get(`/weather/${ id }`);

export const getWeathers = (): Promise<Weather[]> => api.get('/weather');

export const createWeather = (data: CreateWeatherData): Promise<Weather> => api.post('/weather', data);

export const updateWeather = (data: UpdateWeatherData): Promise<Weather> =>
  api.put(`/weather/${ data.id }`, data);

export const deleteWeather = (data: DeleteWeatherData): Promise<Weather> => api.delete(`/weather/${ data.id }`);
