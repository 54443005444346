<template>
  <div class="inline" v-if="$slots.reference">
    <slot name="reference" />
  </div>

  <ElDialog
    append-to-body
    :model-value="props.modelValue"
    :show-close=false
    :before-close="preventClose"
    :align-center="true"
    v-bind="$attrs"
    @update:modelValue="onChange"
    width="100%"
  >
    <template
      #header
      v-if="$slots.header || props.title"
    >
      <div class="flex items-center">
        <div class="my-header flex-1">
          <NkHeading
            v-if="!$slots.header"
            type="small-bold"
          >
            {{ props.title }}
          </NkHeading>
          <slot
            name="header"
            v-else
          />
        </div>
        <button
          class="hover:text-primary transition"
          type="button"
          @click="onChange(false)"
        >
          <NkIcon :size="5" name="NkClose" />
        </button>
      </div>
    </template>

    <slot />

    <template
      #footer
      v-if="$slots.footer"
    >
      <slot name="footer" />
    </template>
  </ElDialog>
</template>

<script setup>
import { defineProps, defineEmits, onBeforeUnmount } from 'vue';
import { ElDialog } from 'element-plus';
import 'element-plus/es/components/dialog/style/css';

import { UPDATE_MODEL_EVENT } from '@/core/consts';

import { NkHeading, NkIcon } from '@/core/components';

import { props as propsDefinition } from './modal.shared';

const props = defineProps(propsDefinition);
const emit = defineEmits([UPDATE_MODEL_EVENT, 'change', 'close']);

const onChange = (value) => {
  emit(UPDATE_MODEL_EVENT, value);
  emit('change', value);

  if (!value) {
    emit('close');
  }
};

onBeforeUnmount(() => {
  onChange(false);
})

const preventClose = () => {
  const prev = true;
}
</script>
