/* eslint-disable */
import styled from 'vue3-styled-components';
import tokens from '@/styles/tokens';
import { props } from './input-text.shared';

const { spacing, colors, borderRadius, borderWidth } = tokens;

export const StInputText = styled('input', props)`
  height: ${() => spacing['7']};
  border: ${() => borderWidth.DEFAULT} solid ${() => colors.primary.DEFAULT};
  border-radius: ${() => borderRadius.DEFAULT};
  background-color: white;
  padding-left: ${() => spacing['3']};
  padding-right: ${() => spacing['3']};
  outline: 0;

  ${({ error }) => error && `border-color: ${colors.danger.DEFAULT};`}

  &:focus {
    border: ${() => borderWidth['2']} solid ${() => colors.primary.DEFAULT};
  }
`;
