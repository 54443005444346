import { RouteRecordRaw } from 'vue-router';

import {
  checkDeviceGroupMiddleware,
  checkDeviceMiddleware,
  checkInspectionMiddleware,
  shouldBeLoggedMiddleware,
} from '@/router/middlewares';

import NkDashboard from '@/teams/visionair/views/NkDashboard.vue';
import NkDeviceGroup from '@/teams/visionair/views/NkDeviceGroup.vue';
import NkDevice from '@/teams/visionair/views/NkDevice.vue';
import NkInspectionGroup from '@/teams/visionair/views/NkInspectionGroup.vue';
import NkUserProfile from '@/teams/security/views/NkUserProfile.vue';
import NkInspection from '@/teams/visionair/views/NkInspection.vue';
import NkRessource from '@/teams/visionair/views/NkRessource.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/visionair/',
    name: 'visionair-dashboard',
    component: NkDashboard,
    meta: {
      entry: 'visionair',
      layout: 'DashboardLayout',
      middlewares: [shouldBeLoggedMiddleware],
    },
    children: [
      {
        path: 'device-group/:deviceGroup',
        name: 'visionair-device-group',
        meta: {
          entry: 'visionair',
          layout: 'DashboardLayout',
          middlewares: [shouldBeLoggedMiddleware, checkDeviceGroupMiddleware],
        },
        component: NkDeviceGroup,
      },
      {
        path: 'device/:device',
        name: 'visionair-device',
        meta: {
          entry: 'visionair',
          layout: 'DashboardLayout',
          middlewares: [shouldBeLoggedMiddleware, checkDeviceMiddleware],
        },
        component: NkDevice,
      },
      {
        path: 'inspection-group/:inspectionGroup',
        name: 'visionair-inspection-group',
        meta: {
          entry: 'visionair',
          layout: 'DashboardLayout',
          middlewares: [shouldBeLoggedMiddleware],
        },
        component: NkInspectionGroup,
      },
      {
        path: 'inspection/:inspection',
        name: 'visionair-inspection',
        meta: {
          entry: 'visionair',
          layout: 'InspectionLayout',
          middlewares: [shouldBeLoggedMiddleware, checkInspectionMiddleware],
        },
        component: NkInspection,
      },
      {
        path: 'profile',
        name: 'visionair-profile',
        component: NkUserProfile,
        meta: {
          entry: 'visionair',
          layout: 'DashboardLayout',
          middlewares: [shouldBeLoggedMiddleware],
        },
      },
      {
        path: 'resource',
        name: 'visionair-resource',
        component: NkRessource,
        meta: {
          entry: 'visionair',
          layout: 'DashboardLayout',
          middlewares: [shouldBeLoggedMiddleware],
        },
      },
    ],
  },
];

export default routes;
