import { isLoggedIn } from 'axios-jwt';

import { getDeviceGroup } from '@/entities/device-group/services';
import { getDevice } from '@/entities/device/services';
import { getInspection } from '@/entities/inspection/services';

import type { RouteMiddlewareContext } from '@/router/types';

export const shouldBeLoggedMiddleware = ({ redirectToLogin, next }: RouteMiddlewareContext) => {
  if (isLoggedIn()) {
    return next();
  }

  return redirectToLogin();
};

export const shouldNotBeLoggedMiddleware = ({ next, from }: RouteMiddlewareContext) => {
  if (!isLoggedIn()) {
    return next();
  }

  return next({
    name: (from.name as string),
  });
};

export const checkDeviceGroupMiddleware = async ({ to, next }: RouteMiddlewareContext) => {
  if (to.params.deviceGroup) {
    try {
      await getDeviceGroup({ id: Number(to.params.deviceGroup) });
      next();
    } catch {
      next({ name: 'visionair-dashboard' });
    }
  }
};

export const checkDeviceMiddleware = async ({ to, next }: RouteMiddlewareContext) => {
  if (to.params.device) {
    try {
      await getDevice({ id: Number(to.params.device) });
      next();
    } catch {
      next({ name: 'visionair-dashboard' });
    }
  }
};

export const checkInspectionMiddleware = async ({ to, next }: RouteMiddlewareContext) => {
  if (to.params.inspection) {
    try {
      await getInspection({ id: Number(to.params.inspection) });
      next();
    } catch {
      next({ name: 'visionair-dashboard' });
    }
  }
};
