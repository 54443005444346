import { ExtractPropTypes, PropType } from 'vue';
import { DataType } from '@/core/components/forms/SearchBar/searchBar.shared';

export const props = {
	data: {
		type: Array as PropType<DataType[]>,
		default: () => []
	},
	selected: {
		type: Number,
		default: null
	},
	loading: {
		type: Boolean,
		default: false
	}
};

export type Props = ExtractPropTypes<typeof props>;
