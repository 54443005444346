import { ExtractPropTypes, Ref } from 'vue';
import { Validation } from '@vuelidate/core';

export const formProps = {
  rules: {
    type: Object,
    required: true,
  },
  fields: {
    type: Object,
    required: true,
  },
};

export const formItemProps = {
  label: {
    type: String,
  },
  prop: {
    type: String,
  },
};

export const formInjectionKey = Symbol('');
export const formItemInjectionKey = Symbol('');
export type FormProps = ExtractPropTypes<typeof formProps>;
export type FormItemProps = ExtractPropTypes<typeof formItemProps>;
export type FormExposition = {
  validator: Ref<Validation<string>>;
};
export type FormItemInjection = {
  id: string;
  error: Ref<boolean>;
};
