import { ExtractPropTypes, PropType } from 'vue';
import tokens from '@/styles/tokens';

export const props = {
  fluid: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String as PropType<keyof typeof tokens.screens>,
  },
  as: {
    type: String,
    default: 'div',
  },
};

export type Props = ExtractPropTypes<typeof props>;
