export const ADMIN_GRANTS = [
  'ROLE_ADMIN',
  'organization_edit',
  'invitation_create',
  'invitation_delete',
  'user_view',
  'user_create',
  'user_edit',
  'user_delete',
  'profile_view',
  'profile_edit'
];
