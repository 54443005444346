import { ComputedRef, Ref, UnwrapNestedRefs } from 'vue';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'vue-query';

import {
  createType,
  deleteType,
  getType,
  getTypeDeviceModel,
  getTypes,
  updateType,
} from '@/entities/type/services';

import {
  CreateTypeData,
  DeleteTypeData,
  GetTypeData,
  Type,
  UpdateTypeData,
} from '@/entities/type/types';
import { Device } from '@/entities/device/types';
import { QueryFilters } from '@/types/services';
import { queryKeyHash } from '@/utils/query';

export const typeQueryName = 'types';
export const useGetTypesQuery = (
  options?: UseQueryOptions<Type[]>,
  filters?: Ref<QueryFilters<Type>>,
) =>
  useQuery(typeQueryName, () => getTypes(filters?.value), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
  });

export const useGetTypeDeviceModel = (data: Ref<GetTypeData>, options?: UseQueryOptions<Device>) =>
  useQuery([typeQueryName, 'model', data], () => getTypeDeviceModel(data.value, true), options);

export const useGetTypeQuery = (
  data: Ref | ComputedRef<GetTypeData>,
  options?: UseQueryOptions<Type>,
) => useQuery([typeQueryName, data], () => getType(data.value), options);

export const useCreateTypeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<CreateTypeData>) => createType(data), {
    onSuccess() {
      queryClient.refetchQueries(typeQueryName, {active: true});
    },
  });
};

export const useUpdateTypeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UnwrapNestedRefs<UpdateTypeData>) => updateType(data), {
    onSuccess({ id }) {
      queryClient.refetchQueries(typeQueryName, {active: true});
      queryClient.refetchQueries([typeQueryName, id], {active: true});
    },
  });
};

export const useDeleteTypeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: UnwrapNestedRefs<DeleteTypeData>) => deleteType(data), {
    onSuccess() {
      queryClient.refetchQueries(typeQueryName, {active: true});
    },
  });
};
