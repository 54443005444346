import tokens from '@/styles/tokens';
import styled from 'vue3-styled-components';
import { fontSizeStyle } from '@/utils/css-in-js';
import { props } from './tile.shared';

export const StTile = styled('div', props)`
  background-color: ${() => tokens.colors.gray['100']};
  padding: ${() => `${tokens.spacing['4']} ${tokens.spacing['6']}`};
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.tile--sm {
    .tile__heading-title {
      ${() => fontSizeStyle(tokens.fontSize.md)}
    }
    .tile__heading-content {
      ${() => fontSizeStyle(tokens.fontSize.sm)}
    }
  }
`;
