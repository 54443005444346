import styled from 'vue3-styled-components';
import { darken, lighten, rem } from 'polished';
import { ElTable } from 'element-plus';

import tokens from '@/styles/tokens';
import { fontSizeStyle } from '@/utils/css-in-js';

// import { props, Props } from './table.shared';

// const getTdColorStyle = ({ type }: Props) => {
//   const styles = {
//     default: {
//       backgroundColor: tokens.colors.gray['300'],
//     },
//     light: {
//       backgroundColor: tokens.colors.gray['100'],
//     },
//   };
//
//   return (styles as any)[type];
// };

export const StTable = styled(ElTable)`
  &[clickable] {
    .el-table__row {
      cursor: pointer;
    }
  }

  table {
    border-spacing: 0 ${() => rem(3)};

    &.el-table__header {
      margin-bottom: ${() => tokens.spacing['2']};
    }

    .el-table__cell {
      padding: 0;
      position: relative;

      &::before {
        content: '';
        width: ${() => rem(3)};
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        background-color: white;
      }
    }

    th {
      border: none !important;

      .cell {
        display: flex !important;
        width: 100%;
        justify-content: center;
        color: black;
        text-transform: uppercase;
        font-weight: 600;
        ${ () => fontSizeStyle(tokens.fontSize.xxs2) }
      }
    }

    tbody {
      tr {
        &.el-table__row--level-1 {
          td {
            background-color: ${ () => lighten(.03, tokens.colors.gray['100']) }
          }
        }
        &.el-table__row--level-2 {
          td {
            background-color: ${ () => lighten(.05, tokens.colors.gray['100']) }
          }
        }
      }
    }

    td {
      background-color: ${ () => tokens.colors.gray['100'] }
      .cell {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: ${ () => rem(56) };
        width: 100%;
        height: 100%;
        ${ () => fontSizeStyle(tokens.fontSize.xxs) }
      }
    }
  }
`;
