import { fabric } from 'fabric';
import { ExtractPropTypes, inject, onBeforeUnmount, onMounted, watch } from 'vue';
import {
  commonProps,
  FabricExposition,
  injectionKey,
} from '@/core/components/data-display/Fabric/fabric.shared';
import { throttle } from 'lodash';

export const useFabricObject = (
  getObject: () => fabric.Object | fabric.Group | undefined,
  props: {
    options: fabric.IObjectOptions | fabric.IGroupOptions;
    position: number;
  } & ExtractPropTypes<typeof commonProps>,
) => {
  const fabricInjection = inject<FabricExposition>(injectionKey);

  const updatePosition = throttle(() => {
    const object = getObject();
    object?.moveTo(props.position);
  }, 100);

  const onCanvasObjectAdded = () => {
    updatePosition();
  };

  const addEventListeners = () => {
    const canvas = fabricInjection?.getCanvas();
    if (canvas) {
      canvas.on('object:added', onCanvasObjectAdded);
    }
  };

  const removeEventListeners = () => {
    const canvas = fabricInjection?.getCanvas();
    if (canvas) {
      canvas.off('object:added', onCanvasObjectAdded);
    }
  };

  watch(
    () => fabricInjection?.isHoldingSpace.value,
    () => {
      const object = getObject();
      if (object) {
        object.set({
          selectable: !fabricInjection?.isHoldingSpace.value,
          hoverCursor: fabricInjection?.isHoldingSpace.value
            ? 'inherit                           '
            : 'move',
        });
      }
    },
  );

  watch(
    () => props.position,
    () => {
      updatePosition();
    },
  );

  onMounted(() => {
    addEventListeners();
  });

  onBeforeUnmount(() => {
    const object = getObject();
    const canvas = fabricInjection?.getCanvas();
    if (canvas && object) {
      if (object.group) {
        object.group.removeWithUpdate(object);
      } else {
        canvas.remove(object);
      }
    }
    removeEventListeners();
  });
};
