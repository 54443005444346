import tokens from '@/styles/tokens';
import { rem } from 'polished';
import styled from 'vue3-styled-components';
import Popper from 'vue3-popper';

// export const POPPER_BORDER_WIDTH_PX = 2;

const { colors, borderRadius, spacing } = tokens;

export const StPopper = styled(Popper)`
    --popper-theme-text-color: inherit;
    --popper-theme-border-radius: ${ () => rem(6) };

    .popper {
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }

    &.nk-popper-styled {
      & > .popper {
        .nk-popper-item-wrapper {
          box-shadow: 2px 2px 8px -3px ${ () => colors.gray.DEFAULT };
          background-color: white;
          border-radius: ${ () => borderRadius.lg }
          padding: ${ () => spacing['2'] } 0;
          .nk-popper-item {
            background-color: white;
            padding: ${ () => spacing['2'] } ${ () => spacing['4'] };
            // &:hover {
            //   background-color: ${ () => colors.gray['50'] }
            // }
          }
        }
      }
    }
`;
