import styled from 'vue3-styled-components';
// eslint-disable-next-line
import tokens, { breakpoint } from '@/styles/tokens';
import { gridProps, gridColProps } from './grid.shared';

const POSITIONS = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  between: 'space-between',
  around: 'space-around',
  stretch: 'stretch',
};

export const StGrid = styled('div', gridProps)`
  margin: -${({ gutter }) => tokens.gutter[gutter]};
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justify }) => POSITIONS[justify]};
  align-items: ${({ align }) => POSITIONS[align]};
`;

export const StCol = styled('div', gridColProps)`
  ${({ offset, columns }) =>
    (offset && columns && `margin-left: ${`${(offset / columns) * 100}%`};`) || ''}
  flex-grow: ${({ grow }) => Number(!!grow)};
  padding: ${({ gutter }) => gutter && tokens.gutter[gutter]};
  flex-basis: ${({ span, columns }) => (columns && span ? `${(span / columns) * 100}%` : '100%')};
  min-width: 0;
  flex-shrink: 0;
  max-width: 100%;
  ${({ growContent }) =>
    growContent &&
    `
    & > * {
        width: 100%;
        height: 100%
    }
  `}

  // Breakpoints
    ${(props) =>
    // eslint-disable-next-line
    (Object.entries(tokens.screens) as [keyof typeof tokens.screens, string][]).map(
      ([bp]) =>
        typeof props[bp] === 'number' &&
        breakpoint(bp)`flex-basis: ${((props[bp] as number) / props.columns) * 100}%;`,
    )}

    // Offsets
    ${(props) =>
    // eslint-disable-next-line
    (Object.entries(tokens.screens) as [keyof typeof tokens.screens, string][]).map(
      ([bp]) =>
        typeof props[`${bp}Offset`] === 'number' &&
        breakpoint(bp)`margin-left: ${((props[`${bp}Offset`] as number) / props.columns) * 100}%;`,
    )}
`;
