import tokens from '@/styles/tokens';
import styled from 'vue3-styled-components';
import { rem } from 'polished';
import { DEFAULT_COLORS as colors } from '@/core/consts';
import { fontSizeStyle } from '@/utils/css-in-js';
import { Props, props } from './color-swatch.shared';

export const StColorSwatch = styled('span', props)`
  height: ${() => rem(35)};
  width: ${() => rem(35)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 100%;
  ${() => fontSizeStyle(tokens.fontSize.xxs)}
  font-weight: 600;
  letter-spacing: ${() => rem(1)};
  ${({ type, color }: Props) => `background-color: ${color || colors[type]}`};
  opacity: ${({ visible }: Props) => (visible ? 1 : 0)};
`;
