import { PropType } from 'vue';
import tokens from '@/styles/tokens';
import { Breakpoint, BreakpointOffset } from './grid.types';

// eslint-disable-next-line
const { gutter, screens } = tokens;
export const injectionKey = Symbol('');
export const alignValues = ['start', 'center', 'end', 'stretch'] as const;
export const justifyValues = ['start', 'center', 'end', 'between', 'around'] as const;

export const gridProps = {
  columns: {
    type: Number,
    default: 12,
  },
  gutter: {
    type: String as PropType<keyof typeof gutter>,
    default: 'md',
  },
  grow: { type: Boolean, default: false },
  align: {
    type: String as PropType<typeof alignValues[number]>,
    default: 'stretch',
  },
  justify: {
    type: String as PropType<typeof justifyValues[number]>,
    default: 'start',
  },
  as: {
    type: String,
    default: 'div',
  },
};

type BreakpointProps = Record<Breakpoint, { type: typeof Number }>;
type BreakpointOffsetProps = Record<BreakpointOffset, { type: typeof Number }>;

export const gridColProps = {
  as: {
    type: String,
    default: 'div',
  },
  span: {
    type: Number,
  },
  offset: {
    type: Number,
  },
  gutter: gridProps.gutter,
  columns: gridProps.columns,
  grow: gridProps.grow,
  growContent: {
    type: Boolean,
    default: false,
  },
  ...(Object.fromEntries(
    Object.keys(screens).map((bp) => [
      bp,
      {
        type: Number,
      },
    ]),
  ) as BreakpointProps),
  ...(Object.fromEntries(
    Object.keys(screens).map((bp) => [
      `${bp}Offset`,
      {
        type: Number,
      },
    ]),
  ) as BreakpointOffsetProps),
};
