import { rem } from 'polished';
import styled from 'vue3-styled-components';
import tokens from '@/styles/tokens';

import { props } from './input-number.shared';

const { spacing, colors } = tokens;

export const StInputNumber = styled('div', props)`
  display: flex;
  align-items: center;
  height: ${ () => spacing['7'] };

  button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  button {
    &:focus {
      outline: 0;
    }
  }

  input[type="number"], input[type="text"] {
    flex: 1;
    text-align: center;
    border: ${() => rem(1)} solid ${() => colors.gray['300']};
    height: 100%;
    padding-left: ${ () => spacing['3'] };
    padding-right: ${ () => spacing['3'] };

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;

    &:focus {
      outline: none;
    }
  }
`;
