/* eslint-disable */
import styled from 'vue3-styled-components';
import tokens from '@/styles/tokens';

export const StSearchBar = styled('div', {})`
	position: relative;
	width: 100%;

	.nk-searchbar__input {
		display: flex;

		input {
			width: 100%;
			background-color: white;
			padding: ${() => tokens.spacing['2']};
			border: 2px solid ${() => tokens.colors.primary['DEFAULT']};
			border-radius: ${() => tokens.borderRadius.lg};
		}
		
		&__icon {
			position: absolute;
			right: ${() => tokens.spacing['2']};
			top: 50%;
			transform: translateY(-50%);
			color: ${() => tokens.colors.primary['DEFAULT']}};
		}
	}

	.nk-searchbar__dropdown {
		background-color: white;
		max-height: 300px;
		right: 0;
		left: 0;
		bottom: 0;
		position: absolute;
		z-index: 9999;
		transform: translateY(calc(100% + ${() => tokens.spacing['2']}));
		transition: transform 0.3s ease-in-out;
		border: 2px solid ${() => tokens.colors.primary['DEFAULT']};
		border-radius: ${() => tokens.borderRadius.lg};
		padding: ${() => tokens.spacing['2']};
		display: flex;
		flex-direction: column;
		overflow: hidden;

		&__content {
			overflow-y: auto;
			overflow-x: hidden;
		}

		&__item {
			padding: ${() => tokens.spacing['2']};
			width: 100%;
			cursor: pointer;
			border-radius: ${() => tokens.borderRadius.sm};

			&:hover, &.is-selected {
				background-color: ${() => tokens.colors.primary['300']};
				color: white;
				
				em {
					color: white;
				}
			}

			em {
				font-style: normal;
				color: ${() => tokens.colors.primary['DEFAULT']};
			}
		}

		&__error {
			text-align: center;
			color: ${() => tokens.colors.primary['DEFAULT']};
		}
	}
`;
