import { ExtractPropTypes } from 'vue';

export const props = {
  refreshKey: {
    type: String,
    default: '',
  },
  inline: {
    type: Boolean,
    default: false,
  },
  styled: {
    type: Boolean,
    default: false,
  }
};

export type Props = ExtractPropTypes<typeof props>

