import { ExtractPropTypes, PropType } from 'vue';

export const typeValues = ['default', 'bold', 'bold-xxs', 'bold-xxs2', 'semibold', 'semibold-xxs', 'semibold-xxs2', 'caption', 'label', 'extra-small', 'light-extra-small'] as const;
export const alignValues = ['left', 'right', 'center', 'justify'] as const;

export const textProps = {
  type: {
    type: String as PropType<typeof typeValues[number]>,
    default: 'default',
  },
  uppercase: {
    type: Boolean,
    default: false,
  },
  align: {
    type: String as PropType<typeof alignValues[number]>,
    default: 'left',
  },
  as: {
    type: String,
    default: 'div',
  },
};

export type Props = ExtractPropTypes<typeof textProps>;
