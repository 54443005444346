/* eslint-disable */
import styled from 'vue3-styled-components';
import { props } from './container.shared';
import tokens, { breakpoint, containers } from '@/styles/tokens';
import { rem } from 'polished';

export const StContainer = styled('div', props)`
  min-height: 1px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  ${({ fluid, size }) =>
    !fluid &&
    (size && size !== 'min'
      ? `
            max-width: ${tokens.screens[size]};
            `
      : Object.entries(tokens.screens).map(
          ([bp, value]) =>
            bp !== 'min' && breakpoint(bp as keyof typeof tokens.screens)`max-width: ${value};`,
        )
  )};

  ${() =>
    Object.entries(containers).map(
      ([bp, { margin, gutter }]) => breakpoint(bp as keyof typeof tokens.screens)`
    padding-left: ${rem(gutter + margin)};
    padding-right: ${rem(gutter + margin)};
  `,
    )}
`;
