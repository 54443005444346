import { Ref, ref, ToRefs, watch } from 'vue';
import { v4 as uuid } from 'uuid';

let observer: ResizeObserver;
type Rect = Pick<DOMRect, 'x' | 'y' | 'width' | 'height' | 'top' | 'right' | 'bottom' | 'left'>;
type ToRefRect = ToRefs<Rect>;
type ObservedEntry = {
  id: string;
  element: Ref<HTMLElement>;
  rect: ToRefRect;
};
const observedEntries: Record<string, ObservedEntry> = {};

const updateRect = (id: string) => {
  const entry = observedEntries[id];
  if (entry) {
    const rect = entry.element.value.getBoundingClientRect();
    Object.entries(entry.rect).forEach(([key, r]) => {
      type Key = keyof Rect;
      // eslint-disable-next-line no-param-reassign
      r.value = rect[key as Key];
    });
  }
};
const createObserver = () =>
  new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      const [id, observedEntry] =
        Object.entries(observedEntries).find(
          ([_, value]) => value.element.value === entry.target,
        ) || [];
      if (id && observedEntry) {
        updateRect(id);
      }
    });
  });

export const useResizeObserver = (el: Ref<HTMLElement | undefined>) => {
  const id = uuid();
  if (!observer) {
    observer = createObserver();
  }
  const rect = {
    x: ref(0),
    y: ref(0),
    width: ref(0),
    height: ref(0),
    top: ref(0),
    right: ref(0),
    bottom: ref(0),
    left: ref(0),
  };
  watch(el, () => {
    if (el.value) {
      const element = el as Ref<HTMLElement>;
      observer.observe(el.value);
      observedEntries[id] = {
        id,
        element,
        rect,
      };
      updateRect(id);
    } else {
      delete observedEntries[id];
    }
  });
  return rect;
};

export default useResizeObserver;
